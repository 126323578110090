import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

import loginReducer from './Pages/LoginPage/reducer';
import dashboardReducer from './Pages/InnerPage/DashboardPage/reducer';
import usersReducer from './Pages/InnerPage/UsersPage/reducer';
import userCardReducer from './Pages/InnerPage/UsersPage/UserCardPage/reducer';
import managersReducer from './Pages/InnerPage/ManagersPage/reducer';
import managerCardReducer from './Pages/InnerPage/ManagersPage/ManagerCardPage/reducer';
import settingsReducer from './Pages/InnerPage/SettingsPage/reducer';

const staticReducers = {
  app: rootReducer,
  auth: loginReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  userCard: userCardReducer,
  managers: managersReducer,
  managerCard: managerCardReducer,
  settings: settingsReducer,
};

const sagaMiddleware = createSagaMiddleware({});

// Configure the store
export default function configureStore(initialState) {
  const middlewares = [sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];

  const actionsBlacklist = [];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist,
        shouldHotReload: false,
        shouldRecordChanges: true,
      })
    : compose;

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(rootSaga);

  // Return the modified store
  return store;
}

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}
