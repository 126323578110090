import { call } from 'redux-saga/effects'

import apiSaga, { apiRequest } from '../index'

export function* getUsersList({ filters }) {
  return yield call(apiSaga, apiRequest.get, `users?filter=${encodeURIComponent(JSON.stringify(filters))}`)
}

export function* getUser({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}`)
}

export function* getUserLogs({ userId, filters }) {
  return yield call(apiSaga, apiRequest.get, `logs/${userId}?filter=${encodeURIComponent(JSON.stringify(filters))}`)
}

export function* updateUser({ userId, values }) {
  return yield call(apiSaga, apiRequest.put, `users/${userId}`, values)
}

export function* getUsersCount() {
  return yield call(apiSaga, apiRequest.get, 'users/count')
}

export function* blockUser({ userId }) {
  return yield call(apiSaga, apiRequest.delete, `users/${userId}`)
}

export function* suspendUser({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/suspend`)
}

export function* activateUser({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/activate`)
}

export function* hideUser({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/hide`)
}

export function* showUser({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/show`)
}

export function* activatePartner({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/activate-partner`)
}

export function* deactivatePartner({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/deactivate-partner`)
}

export function* activateAccountManager({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/activate-manager`)
}

export function* deactivateAccountManager({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/deactivate-manager`)
}

export function* fundUser({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/fund`)
}

export function* convertToActive({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/active`)
}

export function* verifyUser({ userId, value }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/verify`, { value })
}

export function* changeSectionVerifyStatus({ userId, values }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/status`, values)
}

export function* createUser({ values }) {
  return yield call(apiSaga, apiRequest.post, `users`, values)
}

export function* createUserWithClient({ values }) {
  return yield call(apiSaga, apiRequest.post, `clients`, values)
}

export function* createUserBankAccount({ userId, values }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/bank-accounts`, values)
}

export function* updateUserBankAccount({ userId, accountId, values }) {
  return yield call(apiSaga, apiRequest.patch, `users/${userId}/bank-accounts/${accountId}`, values)
}

export function* deleteUserBankAccount({ userId, accountId }) {
  return yield call(apiSaga, apiRequest.delete, `users/${userId}/bank-accounts/${accountId}`)
}

export function* getUserNotes({ userId, filters }) {
  return yield call(
    apiSaga,
    apiRequest.get,
    `users/${userId}/notes?filter=${encodeURIComponent(JSON.stringify(filters))}`
  )
}

export function* createUserNote({ userId, values }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/notes`, values)
}

export function* deleteUserNote({ userId, noteId }) {
  return yield call(apiSaga, apiRequest.delete, `users/${userId}/notes/${noteId}`)
}

export function* exportClients(filter) {
  return yield call(
    apiSaga,
    { method: apiRequest.get, config: { responseType: 'blob' } },
    `tools/export/clients?filter=${encodeURIComponent(JSON.stringify(filter.filter))}`
  )
}

export function* importClients(data) {
  return yield call(apiSaga, apiRequest.post, `clients/import`, data)
}

export function* downloadDocument({ documentId }) {
  return yield call(apiSaga, { method: apiRequest.get, config: { responseType: 'blob' } }, `documents/${documentId}`)
}

export function* deleteDocument({ documentId }) {
  return yield call(apiSaga, apiRequest.delete, `documents/${documentId}`)
}

export function* verifyDocument({ documentId }) {
  return yield call(apiSaga, apiRequest.get, `documents/${documentId}/verify`)
}

export function* confirmEmail({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/confirm-email/${userId}`)
}

export function* addCustomField({ userId, values }) {
  return yield call(apiSaga, apiRequest.post, `clients/${userId}/custom-fields`, values)
}

export function* deleteCustomField({ userId, fieldId }) {
  return yield call(apiSaga, apiRequest.delete, `clients/${userId}/custom-fields/${fieldId}`)
}

export function* changeRiskProfile({ userId, riskProfile }) {
  return yield call(apiSaga, apiRequest.get, `clients/${userId}/risk-profile/${riskProfile}`)
}

export function* assignIeId({ userId, ieId }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/id`, { ieId })
}

export function* changeName({ userId, data }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/change-name`, data)
}

export function* changeEmail({ userId, email }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/change-email`, { email })
}

export function* changePhone({ userId, data }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/change-phone`, data)
}

export function* assignRefId({ userId, refId }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/ref-id`, { refId })
}

export function* closeAccount({ userId, closingDate }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/close`, { closingDate })
}

export function* reopenAccount({ userId, status }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/reopen`, { status })
}

export function* sendPassword({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/send-password`)
}

export function* unbindClient({ userId }) {
  return yield call(apiSaga, apiRequest.get, `users/${userId}/unbind-client`)
}

export function* syncCRM({ userId }) {
  return yield call(apiSaga, apiRequest.get, `tools/crm/sync/${userId}`)
}

export function* createPlatformAccount({ userId, values }) {
  return yield call(apiSaga, apiRequest.post, `users/${userId}/platforms`, values)
}

export function* updatePlatformAccount({ userId, accountId, values }) {
  return yield call(apiSaga, apiRequest.patch, `users/${userId}/platforms/${accountId}`, values)
}

export function* downloadDdlDocument(documentId) {
  return yield call(
    apiSaga,
    { method: apiRequest.get, config: { responseType: 'blob' } },
    `due-diligence/docs/${documentId}`
  )
}

export function* acceptDdlForm(id) {
  return yield call(apiSaga, apiRequest.get, `due-diligence/check/${id}`)
}
