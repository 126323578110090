// @flow
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Row,
  Col,
  Alert,
  Button,
  ButtonGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import Select from 'react-select'

import type { ClientType } from 'core/Models/Client'
import type { UserType } from 'core/Models/User'
import { USER_STATUS, VERIFICATION_STATUS } from 'core/Models/Enums'
import { format } from 'date-fns'
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils'
import * as actions from '../actions'

type VerifyButtonProps = {
  changeSectionStatus: ({ section: string, status: string, message: string }) => void,
  isDisabled: boolean,
  section: string,
  status?: string,
}

function VerifyButton(props: VerifyButtonProps) {
  const { changeSectionStatus, section, status } = props

  return (
    <>
      <Button
        color="success"
        disabled={status === VERIFICATION_STATUS.VERIFIED}
        onClick={() =>
          changeSectionStatus({
            section,
            status: VERIFICATION_STATUS.VERIFIED,
            message: 'Section verified',
          })
        }
      >
        <i className="fas fa-check pr-2"></i>Verify
      </Button>
      <Button
        color="warning"
        disabled={status === VERIFICATION_STATUS.DECLINED || status === VERIFICATION_STATUS.VERIFIED}
        onClick={() =>
          changeSectionStatus({
            section,
            status: VERIFICATION_STATUS.DECLINED,
            message: 'Section declined',
          })
        }
      >
        <i className="fas fa-times pr-2"></i>Decline
      </Button>
    </>
  )
}

type Props = {
  client: ClientType,
  user: UserType,
}

export default function TabVerification(props: Props) {
  const { client, user } = props
  const { userId } = useParams()
  const dispatch = useDispatch()

  // Section Actions
  const [sectionModal, setSectionModal] = useState(false)
  const toggleSectionModal = () => setSectionModal(!sectionModal)
  const [modalValues, setModalValues] = useState({})

  const openSectionModal = (values) => {
    toggleSectionModal()
    setModalValues(values)
  }

  const changeSectionStatus = (e) => {
    e.preventDefault()
    let values: any = {
      section: e.target.section.value,
      status: e.target.status.value,
    }
    if (values.status === 'DECLINED') {
      values.message = e.target.message.value
    }
    const onSuccess = () => {
      toggleSectionModal()
    }
    dispatch(actions.changeSectionVerifyStatus({ userId, values, onSuccess }))
  }

  // Complete Verification
  const [verifyModal, setVerifyModal] = useState(false)
  const toggleVerifyModal = () => setVerifyModal(!verifyModal)
  const verifyUser = (e) => {
    e.preventDefault()
    const value = e.target.after_hooks.value
    dispatch(actions.verifyUser({ userId, value }))
  }

  // Set disabled state for verified users
  const userVerified = user.status === 'VERIFIED' || user.status === 'READY_TO_TRADE' || user.status === 'ACTIVE_CLIENT'
  const isButtonsDisabled = userVerified
  const allSectionsVerified =
    client.status === VERIFICATION_STATUS.VERIFIED && client.documentsStatus === VERIFICATION_STATUS.VERIFIED
  const isVerifyUserButtonDisabled = !allSectionsVerified

  const hookOptions = [
    { value: 'NO', label: 'Do not notify' },
    { value: 'EMAIL', label: 'Send email' },
    { value: 'SMS', label: 'Send SMS', isDisabled: true },
  ]

  return (
    <CardBody>
      {user.clientId !== null && (
        <>
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader tag="h2" className="blue-background">
                  Client Profile
                </CardHeader>
                <CardBody>
                  <Label>
                    <p>
                      <b>Status:</b> {VERIFICATION_STATUS.getLabel(client.status)}
                    </p>
                    <p className="mb-0">
                      <b>Message:</b> {client.statusMessage || 'N/A'}
                    </p>
                  </Label>
                </CardBody>
                <CardFooter>
                  <ButtonGroup size="sm">
                    <VerifyButton
                      changeSectionStatus={openSectionModal}
                      section="CLIENT"
                      status={client.status}
                      isDisabled={isButtonsDisabled}
                    />
                    <Link className="btn btn-primary" to={`/users/${userId || ''}/client`}>
                      <i className="far fa-user pr-2"></i>View Client
                    </Link>
                  </ButtonGroup>
                </CardFooter>
              </Card>
            </Col>

            <Col md={6}>
              <Card>
                <CardHeader tag="h2" className="blue-background">
                  Documents
                </CardHeader>
                <CardBody>
                  <Label>
                    <p>
                      <b>Status:</b> {VERIFICATION_STATUS.getLabel(client.documentsStatus)}
                    </p>
                    <p className="mb-0">
                      <b>Message:</b> {client.documentsStatusMessage || 'N/A'}
                    </p>
                  </Label>
                </CardBody>
                <CardFooter>
                  <ButtonGroup size="sm">
                    <VerifyButton
                      changeSectionStatus={openSectionModal}
                      section="DOCUMENTS"
                      status={client.documentsStatus}
                      isDisabled={isButtonsDisabled}
                    />
                    <Link className="btn btn-primary" to={`/users/${userId || ''}/documents`}>
                      <i className="far fa-file-alt pr-2"></i>View documents
                    </Link>
                  </ButtonGroup>
                </CardFooter>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={4}>
              <Card>
                <CardHeader tag="h3" className="blue-background">
                  Bank Details
                </CardHeader>
                <CardBody>
                  <span>Status: {VERIFICATION_STATUS.getLabel(client.bankDetailsStatus)}</span>
                </CardBody>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <CardHeader tag="h3" className="blue-background">
                  Investment Objectives
                </CardHeader>
                <CardBody>
                  <span>Status: {VERIFICATION_STATUS.getLabel(client.investmentObjectivesStatus)}</span>
                </CardBody>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <CardHeader tag="h3" className="blue-background">
                  Trading Profile
                </CardHeader>
                <CardBody>
                  <span>Status: {VERIFICATION_STATUS.getLabel(client.tradingProfileStatus)}</span>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CardFooter className="mt-4 blue-background">
            <h3>Verification Status: {USER_STATUS.getLabel(user.status)}</h3>
            {userVerified && user.verifiedAt && (
              <span>Verified at {format(new Date(user.verifiedAt ?? 0), STANDARD_TIME_DATE)}</span>
            )}
            {!userVerified && (
              <Button
                color="success"
                className="mt-2"
                onClick={toggleVerifyModal}
                disabled={isVerifyUserButtonDisabled}
              >
                <i className="fas fa-user-check pr-2"></i>Complete Verification
              </Button>
            )}
          </CardFooter>

          <Modal isOpen={sectionModal} toggle={toggleSectionModal}>
            <ModalHeader tag="h2" toggle={toggleSectionModal}>
              Change Section Status
            </ModalHeader>
            <Form onSubmit={changeSectionStatus}>
              <ModalBody>
                <Alert color="primary">Please confirm action: Status Change</Alert>
                <FormGroup>
                  <Label>Section</Label>
                  <Input value={modalValues.section} name="section" disabled />
                </FormGroup>

                <FormGroup>
                  <Label>New Status</Label>
                  <Input value={modalValues.status} name="status" disabled />
                </FormGroup>

                {modalValues.status === 'DECLINED' && (
                  <FormGroup>
                    <Alert color="warning">Rejection Reason will be visible to the user</Alert>
                    <Label>Rejection Reason *</Label>
                    <Input type="textarea" name="message" required />
                  </FormGroup>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="simple" onClick={toggleSectionModal}>
                  Cancel
                </Button>
                <Button color="success">
                  <i className="fas fa-check pr-2" />
                  Apply Changes
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={verifyModal} toggle={toggleVerifyModal}>
            <Form onSubmit={verifyUser}>
              <ModalHeader tag="h2" toggle={toggleVerifyModal}>
                Complete User Verification
              </ModalHeader>

              <ModalBody>
                <Alert color="warning">
                  <b>Please confirm action:</b> Complete Verification for user #{userId}
                </Alert>

                <Label>Notification to the client</Label>
                <Select defaultValue={hookOptions[0]} name="after_hooks" id="after_hooks" options={hookOptions} />
              </ModalBody>

              <ModalFooter>
                <Button color="simple" onClick={toggleVerifyModal}>
                  Cancel
                </Button>
                <Button color="success">
                  <i className="fas fa-check pr-2" />
                  Complete Verificaiton
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </>
      )}
      {!user.clientId && (
        <Card>
          <CardHeader tag="h2" className="blue-background">
            Verification
          </CardHeader>
          <CardBody>
            <span>
              Client for user #{user.id} not found. You can{' '}
              <Link to={`/users/${userId || ''}/client`}>create a client</Link> for this user yourself
            </span>
          </CardBody>
        </Card>
      )}
    </CardBody>
  )
}
