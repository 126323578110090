import type { ClientType } from 'core/Models/Client';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardHeader } from 'reactstrap';
import type { UserType } from 'core/Models/User';

import BankAccountForm from '../../../../../Components/BankAccountForm';

import * as actions from '../actions';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
};

export default function BankAccountPage(props: Props) {
  const { userId, user } = props;
  const { accountId } = useParams();

  const dispatch = useDispatch();

  const createUserBankAccount = ({ values, onSuccess, onError }) => {
    const success = () => {
      window.location.replace(`/users/${userId}/bank_accounts`);
    };
    dispatch(actions.createUserBankAccount({ userId, values, onSuccess: success, onError }));
  };

  const updateUserBankAccount = ({ values, onSuccess, onError }) => {
    const success = () => {
      window.location.replace(`/users/${userId}/bank_accounts`);
    };
    dispatch(actions.updateUserBankAccount({ userId, accountId, values, onSuccess: success, onError }));
  };

  const submitForm = accountId ? updateUserBankAccount : createUserBankAccount;

  const initialValues = accountId
    ? user
        .getIn(['bankAccounts'])
        .find((a) => a.id === parseInt(accountId, 10))
        ?.toJS() ?? {}
    : {};

  // omit id prop
  const { id, ...restInitialValues } = initialValues;

  return (
    <Card>
      <CardHeader className="blue-background" tag="h2">
        {accountId ? `Edit bank account #${accountId}` : 'Add new bank account'}
      </CardHeader>
      <BankAccountForm submitForm={submitForm} initialValues={restInitialValues} />
    </Card>
  );
}
