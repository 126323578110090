// @flow
import * as React from 'react';
import { Container, Row } from 'reactstrap';

import logo from 'core/Assets/img/brand/logo-white.svg';

type Props = {
  children: React.Node,
};

export default function AuthLayout(props: Props) {
  const { children } = props;
  return (
    <div className="auth_splashscreen__admin main-content">
      <div className="header py-7 py-lg-8" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center pb-3">
          <img src={logo} width="200" height="143" alt="Investors Europe" />
        </Row>
        <Row className="justify-content-center">{children}</Row>
      </Container>
    </div>
  );
}
