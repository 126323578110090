import { Map, List } from 'immutable';

import { User } from 'core/Models/User';
import { createReducer } from 'core/Utils/reduxUtils';

import * as actions from './actions';

const initialState = Map({
  manager: new User(),
  managerLogs: Map({
    logs: List(),
    totalCount: 0,
  }),
});

export default createReducer(
  {
    [actions.getManager.success]: (state, { user }) =>
      state.set('manager', new User(user)),
    [actions.getManagerLogs.success]: (state, { data, totalCount }) =>
      state.set('managerLogs', Map({ logs: List(data), totalCount })),
  },
  initialState
);
