import noop from 'lodash/noop';
import { takeLatest, call, put } from 'redux-saga/effects';

import * as managerApi from 'core/Api/admin/dashboardApi';

import * as actions from './actions';

export function* getDashboardInfoSaga({ payload: { filter, onSuccess = noop, onError = noop } = {} }) {
  try {
    console.log(filter);
    const result = yield call(managerApi.getDashboardInfo, filter);
    yield put(actions.getDashboardInfo.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getDashboardInfo.error(e));
    yield call(onError);
  }
}

export default [takeLatest(actions.getDashboardInfo, getDashboardInfoSaga)];
