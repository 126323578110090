import { Map, List, fromJS } from 'immutable';
import { createReducer } from 'core/Utils/reduxUtils';
import * as actions from './actions';

const initialState = Map({
  graph: List(),
  logs: List(),
  upcomingEvents: List(),
  general: Map({
    systemHealth: 'Active',
    counts: Map({
      users: 0,
      verifiedUsers: 0,
      suspendedUsers: 0,
    }),
  }),
});

export default createReducer(
  {
    [actions.getDashboardInfo.success]: (state, payload) => fromJS(payload),
  },
  initialState
);
