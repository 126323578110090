// @flow
import React, {useState} from 'react';
import { Form, Formik } from 'formik';
import { Button, Card, CardHeader, CardBody, CardFooter, Table, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Row, Col, FormGroup } from 'reactstrap';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';
import type { UserType } from 'core/Models/User';

import {InputDateRange} from './InputDateRange';
import { FieldPasswordInput } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';
import { changeAdminPasswordValidationSchema } from 'core/Validation/userValidation';

import * as actions from './actions';

// Check Manager Role
import { USER_ROLES } from 'core/Models/Enums';
import { selectAuthUser } from '../../../../Selectors';

type Props = {
  managerId: number,
  manager: UserType,
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  toggleModal: () => void,
  status?: string,
  text?: string,
  unsubmitForm?: () => void,
  isVerification?: boolean,
}

function ChangePasswordForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting, toggleModal } = props;
  const fieldsDisabled = Boolean(isSubmitting);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">

      <ModalBody>
        <input style={{display:'none'}} type="password" name="fakepassword"/>
        <Row>
          <Col>
            <FormGroup>
              <FieldPasswordInput tabIndex={1} autoComplete="new-password" label="New Password, 8-30 alphanumeric symbols" name="newPassword" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FieldPasswordInput tabIndex={2} autoComplete="new-password" label="Retype New Password" name="retypeNewPassword" disabled={fieldsDisabled} normalize={alphanumWithSymbols}/>
            </FormGroup>
          </Col>
        </Row>

      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={toggleModal}><i className="fas fa-times pr-2"/>Cancel</Button>{' '}
        <Button type="submit" color="success" disabled={!isValid ||fieldsDisabled}><i className="fas fa-key pr-2"/>Set Password</Button>
      </ModalFooter>
      
    </Form>
  );
}

export default function TabManager(props: Props) {
  const { managerId, manager } = props;
  const dispatch = useDispatch();

  const suspendManager = () => dispatch(actions.suspendManager({ managerId }));
  const activateManager = () => dispatch(actions.activateManager({ managerId }));

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [passwordModal, setPasswordModal] = useState(false);
  const togglePasswordModal = () => setPasswordModal(!passwordModal);

  const handleSubmit = (e) => {
    e.preventDefault();

    const startDate = new Date(e.target.startDate.value).toISOString();
    const endDate = new Date(e.target.endDate.value).toISOString();
    
    dispatch(actions.exportActivity({ 
      managerId, 
      filters: {
        where: {
          submitterId: managerId,
          createdAt: {
            between: [startDate, endDate]
          },
        },
      } 
    }));
  }

  const roles = manager.get('roles');

  const authUser = useSelector(selectAuthUser);

  const submitForm = (values) => dispatch(actions.setManagerPassword({ managerId,  ...values}));

  
  const onSubmit = (values, { setSubmitting }) => {
    const { newPassword } = values;

    const transformedValues = {
      newPassword: `${newPassword}`,
    };

    const onSuccess = () => {
      setSubmitting(false);
      togglePasswordModal();
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values: transformedValues, onSuccess, onError });
  };

  return (
    <CardBody>
      <Card>
        <CardHeader className="blue-background" tag="h2">Manager Information</CardHeader>
        <Table bordered striped>
          <thead>
          <tr>
            <th style={{ width: 200 }}>Field</th>
            <th>Value</th>
            <th style={{ width: 200 }}>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">Full Name</th>
            <td>#{manager.id} {manager.firstName} {manager.lastName}</td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{manager.email}</td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">Password</th>
            <td>*********</td>
            <td>
              {authUser.hasRole([USER_ROLES.SUPERADMIN]) && (
                <Button color="primary" size="sm" onClick={togglePasswordModal}><i className="fas fa-key pr-2"/>Set Password</Button>
              )}
            
            </td>
          </tr>
          <tr>
            <th scope="row">Created At</th>
            <td>{format(new Date(manager.createdAt ?? 0), STANDARD_TIME_DATE)}</td>
            <td></td>
          </tr>
          <tr>
            <th scope="row">Is Suspended</th>
            <td>{manager.suspended ? 'Yes' : 'No'}</td>
            <td>
              {!manager.suspended && <Button color="warning" size="sm" onClick={suspendManager}><i className="fas fa-lock pr-2"></i>Suspend Manager</Button>}
              {manager.suspended && <Button color="success" size="sm" onClick={activateManager}><i className="fas fa-unlock pr-2"></i>Activate Manager</Button>}
            </td>
          </tr>
          <tr>
            <th scope="row">Roles</th>
            <td>{roles.map((role, idx) => 
              <Badge color="success" className="mr-2" key={idx} pill>{role.roleId}</Badge>
            )}</td>
            <td></td>
          </tr>
          </tbody>
        </Table>
      <CardFooter>
        {authUser.hasRole([USER_ROLES.SUPERADMIN]) && <Button color="success" onClick={toggleModal}><i className="far fa-file-excel pr-2"/>Export Activity</Button>}
        
        <Modal isOpen={modal} toggle={toggleModal}>
          <Form role="form" onSubmit={handleSubmit}>
            <ModalHeader tag="h2" toggle={toggleModal}>Export Activity for manager #{manager.id}</ModalHeader>
            <ModalBody>
              <InputDateRange />
            </ModalBody>
            <ModalFooter>
              <Button color="simple" onClick={toggleModal}><i className="fas fa-times pr-2"/>Cancel</Button>{' '}
              <Button color="success"><i className="far fa-file-excel pr-2"/>Export to Excel</Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={passwordModal} toggle={togglePasswordModal}>
  
            <ModalHeader tag="h2" toggle={togglePasswordModal}>New password for manager #{manager.id}</ModalHeader>

            <Formik
              onSubmit={onSubmit}
              render={(props) => <ChangePasswordForm toggleModal={toggleModal} {...props} />}
              validationSchema={changeAdminPasswordValidationSchema}
            />

    

        </Modal>
       
      </CardFooter>
      </Card>
    </CardBody>
  );
}
