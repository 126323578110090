import { call } from 'redux-saga/effects';

import apiSaga, { apiRequest } from '../index';

export function* loginUser(credentials) {
  return yield call(apiSaga, apiRequest.post, 'login', credentials);
}

export function* getUser() {
  return yield call(apiSaga, apiRequest.get, 'me');
}
