import { Map, List, fromJS } from 'immutable';

import { User } from 'core/Models/User';
import { listOf } from 'core/Utils/immutableUtils';
import { createReducer } from 'core/Utils/reduxUtils';

import * as actions from './actions';

const initialState = Map({
  managers: List(),
  managerRoles: List(),
  totalCount: 0,
});

export default createReducer(
  {
    [actions.getManagersList.success]: (state, { data, totalCount }) =>
      state.set('managers', listOf(User, data)).set('totalCount', totalCount),
    [actions.getManagerRoles.success]: (state, payload) =>
      state.set('managerRoles', fromJS(payload)),
  },
  initialState
);
