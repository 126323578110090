// @flow
import { Formik } from 'formik';
import React from 'react';
import { FormGroup, Row, Col, Form, Button, CardBody, CardFooter } from 'reactstrap';

import { PLATFORM_TYPES } from 'core/Models/Enums';
import { FieldInput, FieldSelect, FieldDatePicker } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';
import { platformValidationSchema } from 'core/Validation/clientValidation';

type FormikEnhancerProps = {
  submitForm: (values: any) => void,
  initialValues?: any,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { submitForm, initialValues } = props;

  const onSubmit = (values, { setSubmitting }) => {
    const data = {
      ...values,
    };
    const cb = () => {
      setSubmitting(false);
    };
    submitForm({ values: data, onSuccess: cb, onError: cb });
  };
  return (
    <Formik
      autoComplete="off"
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={(props) => <PlatformAccountForm {...props} />}
      validationSchema={platformValidationSchema}
      isInitialValid={() => platformValidationSchema.isValidSync(initialValues)}
      enableReinitialize
    />
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  values: any,
  isValid: boolean,
  isSubmitting: boolean,
};

function PlatformAccountForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting } = props;

  return (
    <>
      <Form role="form" onSubmit={handleSubmit} autoComplete="off">
        <CardBody>
          <Row>
            <Col xl="4" md="6">
              <FormGroup>
                <FieldSelect
                  label="Trading Platform *"
                  name="platform"
                  id="platform"
                  disabled={isSubmitting}
                  options={PLATFORM_TYPES.selectOptions}
                />
              </FormGroup>
            </Col>
            <Col xl="4" md="6">
              <FormGroup>
                <FieldInput
                  label="Account Number *"
                  name="accountNumber"
                  id="accountNumber"
                  disabled={isSubmitting}
                  normalize={alphanumWithSymbols}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl="4" md="6">
              <FormGroup>
                <FieldDatePicker
                  label="Opening Date *"
                  name="openingDate"
                  showYearDropdown
                  scrollableYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={60}
                  disabled={isSubmitting}
                />
              </FormGroup>
            </Col>
            <Col xl="4" md="6">
              <FormGroup>
                <FieldDatePicker
                  label="Closing Date"
                  name="closingDate"
                  showYearDropdown
                  scrollableYearDropdown
                  showMonthDropdown
                  yearDropdownItemNumber={60}
                  isClearable
                  disabled={isSubmitting}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button type="submit" color="success" disabled={!isValid || isSubmitting}>
            Save Changes
          </Button>
        </CardFooter>
      </Form>
    </>
  );
}
