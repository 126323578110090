// @flow
import { Formik } from 'formik';
import React from 'react';
import { FormGroup, Row, Col, Form, Button, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { FieldInput, FieldSelect } from 'core/Components/Form';
import { alphanumWithSymbols } from 'core/Components/Form/normalizers';
import { USER_NOTE_TYPES } from 'core/Models/Enums';

import * as actions from '../actions';

const validationSchema = Yup.object().shape({
  type: Yup.string()
    .required('Type is required'),
  content: Yup.string()
    .required('Content is required'),
});

type FormikEnhancerProps = {
  userId: number,
  initialValues?: any,
  closeModal: () => void,
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { userId, closeModal } = props;
  const dispatch = useDispatch();

  const submitForm = ({ values, onSuccess, onError }) =>
    dispatch(actions.createUserNote({ userId, values, onSuccess, onError }));

  const onSubmit = (values, { setSubmitting }) => {
    const data = {
      ...values,
    };
    const onSuccess = () => {
      closeModal();
      setSubmitting(false);
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values: data, onSuccess, onError });
  };

  return (
    <Formik
      autoComplete="off"
      onSubmit={onSubmit}
      render={(props) => <UserNoteForm closeModal={closeModal} {...props} />}
      validationSchema={validationSchema}
    />
   );
}

type Props = {
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  closeModal: () => void,
};

function UserNoteForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting, closeModal } = props;
  let type;

  return (
    <>
      <ModalHeader tag="h2">Create New Note</ModalHeader>

      <Form role="form" onSubmit={handleSubmit} autoComplete="off">
        <ModalBody>
          <Row>
            <Col xl="12" md="12">
              <Alert color="warning">
                <b>Attention!</b> Public notes will be visible to a client.
              </Alert>
              <FormGroup className="mt-2">
                <FieldSelect label="Type *" name="type" id="type" disabled={isSubmitting} inputValue={type} options={USER_NOTE_TYPES.selectOptions} />
              </FormGroup>
            </Col>
          </Row>
          
          <Row>
            <Col xl="12" md="12">
              <FormGroup>
                <FieldInput label="Note *" rows={5} placeholder="Message..." type="textarea" name="content" id="content" disabled={isSubmitting} normalize={alphanumWithSymbols} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="simple" onClick={closeModal}>Cancel</Button>
          <Button type="submit" color="success" disabled={!isValid || isSubmitting}>
            <i className="fas fa-plus pr-2"></i>Add Note
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
}
