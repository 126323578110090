import { takeLatest, call, put, delay, take, race } from 'redux-saga/effects';
import noop from 'lodash/noop';

import * as authApi from 'core/Api/admin/authApi';
import * as localStorageApi from 'core/Api/localStorageApi';

import * as actions from './actions';
import * as mainActions from '../../Actions';

function* authorizationSaga() {
  try {
    const token = yield call(localStorageApi.getToken);
    if (token) {
      const user = yield call(authApi.getUser);
      yield call(localStorageApi.setUserData, user);
      yield put(mainActions.userLoggedIn({ user, token }));
    }
  } catch (e) {
    yield put(mainActions.userLoggedOut());
  }
}

function* pingUserSaga() {
  try {
    while (true) {
      const { logout, update } = yield race({
        logout: take(mainActions.userLoggedOut),
        update: delay(2 * 15000),
      });
      if (update) {
        yield call(authApi.getUser);
      }
      if (logout) {
        break;
      }
    }
  } catch (e) {
    yield put(mainActions.userLoggedOut());
  }
}

function* logoutUserSaga() {
  yield call(localStorageApi.removeUserData);
  yield call(localStorageApi.removeToken);
}

export function* loginUserSaga({ payload: { values, onSuccess = noop, onError = noop } }) {
  try {
    const user = yield call(authApi.loginUser, values);
    const token = user?.token;
    if (token) {
      yield call(localStorageApi.setUserData, user);
      yield call(localStorageApi.setToken, token);
      yield put(mainActions.userLoggedIn({ user, token }));
      yield call(onSuccess);
    } else {
      throw new Error('No token received');
    }
  } catch (e) {
    yield put(mainActions.userLoggedOut());
    yield call(onError);
  }
}


export default [
  authorizationSaga(),
  takeLatest(actions.loginUser, loginUserSaga),
  takeLatest(mainActions.userLoggedIn, pingUserSaga),
  takeLatest(mainActions.userLoggedOut, logoutUserSaga),
];
