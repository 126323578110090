// @flow
import React from 'react';
import { Card, CardHeader, CardBody, Table} from 'reactstrap';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { USER_STATUS } from 'core/Models/Enums';
import { STANDARD_DATE_TIME } from 'core/Utils/dateUtils';
import type { UserType } from 'core/Models/User';
import type { ClientType } from 'core/Models/Client';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType
}

export default function TabPartner(props: Props) {
  const { user } = props;

  if(!user.isPartner) {
    return (
    <CardBody>
      <Card>
        <CardHeader tag="h2" className="blue-background">Partner Information</CardHeader>
        <CardBody>This user does not have partner access</CardBody>
        </Card>
      </CardBody>
    );
  }

  return (
    <CardBody>
      <Card>
        <CardHeader tag="h2" className="blue-background">Partner Information</CardHeader>
        <Table className="fixed-rows" responsive hover bordered striped>
          <thead>
          <tr>
            <th style={{ width: 200 }}>Referral Client</th>
            <th>Date</th>
            <th>Email</th>
            <th>Current Status</th>
          </tr>
          </thead>
          <tbody>

          {user.refferals.map(referral =>
            <tr>
              <th scope="row">
              <Link to={`/users/${referral.id}/user`}>#{referral.id} {referral.firstName} {referral.lastName}</Link>
                
              </th>
              <td>{format(new Date(referral.createdAt), STANDARD_DATE_TIME)}</td>
              <td>{referral.email}</td>
              <td>
                <span className={referral.status === "NOT_VERIFIED" ? 'text-muted' : 'text-success'} id="verificationDateTooltip">
                  {referral.status === "NOT_VERIFIED" && <i className="far fa-circle pr-2" />}
                  {referral.status === "IN_VERIFICATION" && <i className="far fa-clock pr-2" />}
                  {referral.status === "VERIFIED" && <i className="far fa-check-circle pr-2" />}
                  {referral.status === "READY_TO_TRADE" && <i className="far fa-check-circle pr-2" />}
                  {referral.status === "ACTIVE_CLIENT" && <i className="far fa-check-circle pr-2" />}
                  {USER_STATUS.getLabel(referral.status)} 
                </span>
              </td>
            </tr>
          )}

        </tbody>
      </Table>
    </Card>

    </CardBody>
  );
}