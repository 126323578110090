import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalHeader } from 'reactstrap';
import { Formik } from 'formik';

import { userAdminValidationSchema } from 'core/Validation/userValidation';
import { Modal } from 'core/Components/Modal';

import UserForm from '../../../Components/UserForm';

import * as actions from './actions';

type Props = {
  isOpen: boolean,
  toggleModal: () => void,
};

export default function CreateUserModal(props: Props) {
  const { isOpen, toggleModal } = props;

  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting }) => {
    const { passwordConfirmation, phoneNumber, ...restValues } = values;

    const data = {
      ...restValues,
      phoneCode: `+${phoneNumber.countryCode}`, phoneNumber: `${phoneNumber.phoneWithoutCode}`,
    };

    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };

    const onError = () => {
      setSubmitting(false);
    };

    dispatch(actions.createUser({ values: data, onSuccess, onError }))
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="blue-background" tag="h2">
        New user
      </ModalHeader>
        <Formik
          autoComplete="off"
          onSubmit={onSubmit}
          render={(props) => <UserForm {...props} />}
          validationSchema={userAdminValidationSchema}
        />
    </Modal>
  );
}
