// @flow
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ManagersListPage from './ManagersListPage';
import ManagerCardPage from './ManagerCardPage';

export default function UsersPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        component={ManagersListPage}
      />
      <Route
        path={`${path}/:managerId`}
        component={ManagerCardPage}
      />
    </Switch>
  );
}
