import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { CardBody } from 'reactstrap'

import type { ClientType } from 'core/Models/Client'
import type { UserType } from 'core/Models/User'

import DueDiligenceList from './DueDiligenceList'
import DueDiligencePage from './DueDiligencePage'

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
}

export default function TabBankAccounts(props: Props) {
  const { userId, user, client } = props
  const { path } = useRouteMatch()

  return (
    <CardBody>
      <Switch>
        <Route
          exact
          path={path}
          render={(routeProps) => <DueDiligenceList {...routeProps} userId={userId} client={client} user={user} />}
        />
        <Route
          path={`${path}/:formId`}
          render={(routeProps) => <DueDiligencePage {...routeProps} userId={userId} client={client} user={user} />}
        />
      </Switch>
    </CardBody>
  )
}
