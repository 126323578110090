import { List } from 'immutable';
import { createSelector } from 'reselect';

const selectManagersSpace = (state) => state.get('managers');

export const selectManagers = createSelector(
  selectManagersSpace,
  (managers) => managers.get('managers', List())
);

export const selectManagerRoles = createSelector(
  selectManagersSpace,
  (managers) => managers.get('managerRoles', List())
);


export const selectManagersTotalCount = createSelector(
  selectManagersSpace,
  (managers) => managers.get('totalCount', 0)
);
