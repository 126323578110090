import React from 'react';
import { Card, CardHeader, CardBody, Table, InputGroup, InputGroupAddon } from 'reactstrap';

import { FieldInput, FieldSelect } from 'core/Components/Form';
import { onlyLatinLetters, onlyNumbers } from 'core/Components/Form/normalizers';
import { FREQUENCY } from 'core/Models/Enums';

type Props = {
  fieldsDisabled: boolean,
};

export default function TradingProfile(props: Props) {
  const { fieldsDisabled } = props;

  return (
    <CardBody>
      <Card>

        <CardHeader tag="h2" className="blue-background">Client Trading Profile</CardHeader>

        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Asset Class</th>
              <th>Experience</th>
              <th>Knowledge</th>
              <th>Avg Frequency</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Stocks</td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.stocksExperience"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.stocksKnowledge"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <FieldSelect
                  options={FREQUENCY.selectOptions}
                  name="tradingProfile.stocksFrequency"
                  id="stocksFrequency"
                  disabled={fieldsDisabled}
                />
              </td>
            </tr>
            <tr>
              <td>Bonds</td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.bondsExperience"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.bondsKnowledge"
                    id="bondsKnowledge"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />{' '}
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <FieldSelect
                  options={FREQUENCY.selectOptions}
                  name="tradingProfile.bondsFrequency"
                  id="bondsFrequency"
                  disabled={fieldsDisabled}
                />
              </td>
            </tr>
            <tr>
              <td>Forex</td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.forexExperience"
                    id="forexExperience"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.forexKnowledge"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />{' '}
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <FieldSelect options={FREQUENCY.selectOptions} name="tradingProfile.forexFrequency" disabled={fieldsDisabled} />
              </td>
            </tr>
            <tr>
              <td>Derivatives</td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.derivativesExperience"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <InputGroup style={{ width: '70px' }}>
                  <FieldInput
                    name="tradingProfile.derivativesKnowledge"
                    disabled={fieldsDisabled}
                    normalize={onlyNumbers}
                    placeholder="0"
                    bsSize="sm"
                    maxLength="2"
                  />{' '}
                  <InputGroupAddon addonType="append">/10</InputGroupAddon>
                </InputGroup>
              </td>
              <td>
                <FieldSelect options={FREQUENCY.selectOptions} name="tradingProfile.derivativesFrequency" disabled={fieldsDisabled} />
              </td>
            </tr>
            <tr>
              <td>Experience Description</td>
              <td colSpan={3}>
                <FieldInput name="tradingProfile.description" disabled={fieldsDisabled} normalize={onlyLatinLetters} type="textarea" />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </CardBody>
  );
}
