import { all } from 'redux-saga/effects';

import LoginSagas from './Pages/LoginPage/sagas';
import UserSagas from './Pages/InnerPage/UsersPage/sagas';
import UserCardSagas from './Pages/InnerPage/UsersPage/UserCardPage/sagas';
import ManagerSagas from './Pages/InnerPage/ManagersPage/sagas';
import ManagerCardSagas from './Pages/InnerPage/ManagersPage/ManagerCardPage/sagas';
import DashboardSagas from './Pages/InnerPage/DashboardPage/sagas';
import SettingsSagas from './Pages/InnerPage/SettingsPage/sagas';

export default function* root() {
  yield all([...LoginSagas, ...UserSagas, ...UserCardSagas, ...ManagerSagas, ...ManagerCardSagas, ...DashboardSagas, ...SettingsSagas]);
}
