import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, ButtonGroup, Card, CardHeader, CardBody, CardFooter, Alert } from 'reactstrap';
import * as Yup from 'yup';

import type { ClientType } from 'core/Models/Client';
import { CLIENT_TYPE } from 'core/Models/Enums';
import type { UserType } from 'core/Models/User';
import mapClientToProps from 'core/Transformers/mapClientToProps';
import { transformDateToString } from 'core/Utils/dateUtils';
import {
  corporateValidationSchema,
  individualValidationSchema,
  investmentObjectivesValidationSchema,
  tradingProfileValidationSchema,
} from 'core/Validation/clientValidation';

import ClientInformation from './ClientInformation';
import InvestmentObjectives from './InvestmentObjectives';
import TradingProfile from './TradingProfile';

const mapPropsToValues = (user, client, clientType) => ({
  client: {
    ...mapClientToProps(user, client, clientType),
  },
  investmentObjectives: {
    ...client.investmentObjectives.toJS(),
  },
  tradingProfile: {
    ...client.tradingProfile.toJS(),
  },
});

const makeValidationSchema = (clientType) => {
  return Yup.object().shape({
    client: clientType === CLIENT_TYPE.INDIVIDUAL ? individualValidationSchema : corporateValidationSchema,
    tradingProfile: tradingProfileValidationSchema,
    investmentObjectives: investmentObjectivesValidationSchema,
  });
};

type FormikEnhancerProps = {
  user: UserType,
  client: ClientType,
  submitForm: { values: any, onSuccess: () => void, onError: () => void },
};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const { user, client, submitForm } = props;
  const [clientType, setClientType] = useState(client.type || CLIENT_TYPE.INDIVIDUAL);

  const onSubmit = (values, { setSubmitting }) => {
    const {
      client: { contacts, tins, passportExpiryDate, birthday, companyIncorporationDate, ...restValues },
      tradingProfile,
      investmentObjectives,
    } = values;

    const {
      stocksFrequency,
      bondsFrequency,
      derivativesFrequency,
      forexFrequency,
      description,
      ...tradingProfileIntValues
    } = tradingProfile;

    const tradingProfileParseIntValues = Object.entries(tradingProfileIntValues).reduce((n, [k, v]) => {
      n[k] = parseInt(v, 10);
      return n;
    }, {});

    const data = {
      client: {
        ...restValues,
        ...(passportExpiryDate && { passportExpiryDate: transformDateToString(passportExpiryDate) }),
        ...(birthday && { birthday: transformDateToString(birthday) }),
        ...(companyIncorporationDate && { companyIncorporationDate: transformDateToString(companyIncorporationDate) }),
      },
      contacts,
      tins,
      tradingProfile: {
        ...tradingProfileParseIntValues,
        stocksFrequency,
        bondsFrequency,
        derivativesFrequency,
        forexFrequency,
        description,
      },
      investmentObjectives,
    };
    const onSuccess = () => {
      setSubmitting(false);
    };
    const onError = () => {
      setSubmitting(false);
    };
    submitForm({ values: data, onSuccess, onError });
  };

  const initialValues = mapPropsToValues(user, client, clientType);
  const validationSchema = makeValidationSchema(clientType);

  return (
    <div>
      {user.id && !client.type ? (
        <CardBody>
          <Alert color="primary">A client has not been created for this user. You can create it yourself</Alert>
          <Card>
            <CardHeader className="blue-background" tag="h2">
              Account type
            </CardHeader>

            <CardBody>
              <p>Account type for new client:</p>
              <ButtonGroup>
                <Button
                  color={clientType === CLIENT_TYPE.INDIVIDUAL ? 'brand' : 'simple'}
                  onClick={() => setClientType(CLIENT_TYPE.INDIVIDUAL)}
                >
                  Individual
                </Button>
                <Button
                  color={clientType === CLIENT_TYPE.CORPORATE ? 'brand' : 'simple'}
                  onClick={() => setClientType(CLIENT_TYPE.CORPORATE)}
                >
                  Corporate
                </Button>
              </ButtonGroup>
            </CardBody>
          </Card>
        </CardBody>
      ) : null}
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={(props) => <ClientForm {...props} clientType={clientType} />}
        isInitialValid={() => validationSchema.isValidSync(initialValues)}
        enableReinitialize
      />
    </div>
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  values: any,
  isValid: boolean,
  isSubmitting: boolean,
  user: UserType,
  clientType: string,
};

function ClientForm(props: Props) {
  const { handleSubmit, clientType, isSubmitting, values, isValid } = props;

  const fieldsDisabled = Boolean(isSubmitting);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ClientInformation clientType={clientType} values={values} fieldsDisabled={fieldsDisabled} />
      <InvestmentObjectives values={values} fieldsDisabled={fieldsDisabled} />
      <TradingProfile fieldsDisabled={fieldsDisabled} />
      <CardFooter>
        <Button color="brand" type="submit" disabled={Boolean(fieldsDisabled || !isValid)}>
          Update
        </Button>
      </CardFooter>
    </Form>
  );
}
