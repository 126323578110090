import React from 'react';
import { useDispatch } from 'react-redux';

import type { ClientType } from 'core/Models/Client';
import type { UserType } from 'core/Models/User';

import ClientForm from '../../../../Components/ClientForm';

import * as actions from './actions';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
};

export default function TabClient(props: Props) {
  const { user, client } = props;
  const dispatch = useDispatch();
  const submitForm = ({ values, onSuccess, onError }) =>
    dispatch(actions.updateUser({ userId: user.id, values, onSuccess, onError }));

  return <ClientForm submitForm={submitForm} client={client} user={user} />;
}
