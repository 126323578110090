// @flow

import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Button, Row, Col } from 'reactstrap';
import { Modal } from 'core/Components/Modal';

import { addCustomFieldValidationSchema } from 'core/Validation/userValidation';
import { FieldInput } from 'core/Components/Form';
import { onlyLatinLetters, alphanumWithSymbols } from 'core/Components/Form/normalizers';

import * as actions from '../actions';

type AddFieldModalProps = {
  toggleModal: () => void,
  isOpen: boolean,
  userId: number,
};

export default function AddFieldModal(props: AddFieldModalProps) {
  const { toggleModal, isOpen, userId } = props;
  const dispatch = useDispatch();
 
  const submitForm = ({ values, onSuccess, onError })  => {
    const data = {
      userId, 
      values, 
      onSuccess, 
      onError 
    }
    dispatch(actions.addCustomField(data));
  }
   

  const onSubmit = (values, { setSubmitting }) => {

    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };

    const onError = () => {
      setSubmitting(false);
    };

    submitForm({ values, onSuccess, onError });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="blue-background" tag="h2" toggle={toggleModal}>
        Add Custom Field
      </ModalHeader>
      <Formik
        autoComplete="off"
        onSubmit={onSubmit}
        render={(props) => <ChangePasswordForm {...props} />}
        validationSchema={addCustomFieldValidationSchema}
      />
    </Modal>
  );
}

type Props = {
  handleSubmit: (values: any) => void,
  isValid: boolean,
  isSubmitting: boolean,
  status?: string,
  text?: string,
  unsubmitForm?: () => void,
  isVerification?: boolean,
};

function ChangePasswordForm(props: Props) {
  const { handleSubmit, isValid, isSubmitting } = props;
  const fieldsDisabled = Boolean(isSubmitting);

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ModalBody>

        <Row>
          <Col>
            <FormGroup>
              <FieldInput label="Field Name" name="name" disabled={fieldsDisabled} normalize={onlyLatinLetters} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <FieldInput label="Field Value" type="textarea" rows={5} name="value" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
            </FormGroup>
          </Col>
        </Row>

      </ModalBody>

      <ModalFooter>
        <Button type="submit" color="success" disabled={!isValid ||fieldsDisabled}><i className="fas fa-plus pr-2" />Create Field</Button>
      </ModalFooter>
      

    </Form>
  );
}
