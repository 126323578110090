// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Form, FormGroup, InputGroup } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { FieldInput } from 'core/Components/Form';

import { selectAuthUserToken } from '../../Selectors';
import { AuthLayout } from '../../Components/Layout';

import * as actions from './actions';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password too short'),
});

type FormikEnhancerProps = ContextRouter & {};

export default function FormikEnhancer(props: FormikEnhancerProps) {
  const dispatch = useDispatch();
  const loginUser = ({ values, onSuccess, onError }) => dispatch(actions.loginUser({ values, onSuccess, onError }));

  const authUserToken = useSelector(selectAuthUserToken);

  const { from } = props.location.state || { from: { pathname: '/' } };

  if (authUserToken) {
    return <Redirect to={from} />;
  }

  const onSubmit = (values, { setSubmitting }) => {
    const onSuccess = () => {
      setSubmitting(false);
    };
    const onError = () => {
      setSubmitting(false);
    };
    loginUser({ values, onSuccess, onError });
  };

  return <Formik autoComplete="off" component={LoginForm} onSubmit={onSubmit} validationSchema={validationSchema} />;
}

type LoginFormProps = {
  isSubmitting: boolean,
  isValid: boolean,
  handleSubmit: () => void,
};

function LoginForm(props: LoginFormProps) {
  const { isSubmitting, isValid, handleSubmit } = props;

  return (
    <AuthLayout>
      <Col lg="4" md="6">
        <Card className="shadow border-0 transparent">
          <CardBody className="px-lg-0 py-lg-0 px-3">
            <h1 className="text-white text-center">MANAGER PORTAL</h1>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <small className="auth_label text-white">Email</small>
                <InputGroup>
                  <FieldInput
                    type="email"
                    name="email"
                    className="auth_input"
                    disabled={isSubmitting}
                    autoComplete="username"
                    placeholder="user@investorseurope.com"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <small className="auth_label text-white">Password</small>
                <InputGroup className="input-group-alternative">
                  <FieldInput
                    type="password"
                    name="password"
                    className="auth_input"
                    disabled={isSubmitting}
                    autoComplete="current-password"
                    placeholder="********"
                  />
                </InputGroup>
              </FormGroup>
              <Button className="my-2 auth_button" color="brand" type="submit" disabled={isSubmitting || !isValid}>
                LOG IN
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </AuthLayout>
  );
}
