import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectAuthUserToken } from '../Selectors';

export function PrivateRoute(props) {
  const { component: Component, ...rest } = props;
  const authUserToken = useSelector(selectAuthUserToken);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authUserToken) {
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}
