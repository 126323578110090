import { Map, List } from 'immutable'

import { User } from 'core/Models/User'
import { Client } from 'core/Models/Client'
import { createReducer } from 'core/Utils/reduxUtils'

import * as actions from './actions'

const initialState = Map({
  user: new User(),
  client: new Client(),
  userLogs: Map({
    logs: List(),
    totalCount: 0,
  }),
  userNotes: Map({
    notes: List(),
    totalCount: 0,
  }),
})

export default createReducer(
  {
    [actions.getUser.success]: (state, { user, client }) =>
      state.set('user', new User(user)).set('client', new Client(client)),
    [actions.getUserLogs.success]: (state, { data, totalCount }) =>
      state.set('userLogs', Map({ logs: List(data), totalCount })),
    [actions.getUserNotes.success]: (state, { notes, totalCount }) =>
      state.set('userNotes', Map({ notes: List(notes), totalCount })),
  },
  initialState
)
