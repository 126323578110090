import type { ClientType } from 'core/Models/Client';
import React from 'react';
import { Button, Card, CardBody, CardHeader, CardFooter, Table, ButtonGroup } from 'reactstrap';

import { Link, useRouteMatch } from 'react-router-dom';
import { PLATFORM_TYPES } from 'core/Models/Enums';
import type { UserType } from 'core/Models/User';

import { format } from 'date-fns';
import { STANDARD_DATE } from 'core/Utils/dateUtils';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
};

export default function BankAccountsList(props: Props) {
  const { client } = props;
  const { url } = useRouteMatch();

  return (
    <Card>
      <CardHeader tag="h2" className="blue-background">
        Trading Platform Accounts
      </CardHeader>
      {client.platforms.size > 0 && (
        <Table responsive bordered striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Platform</th>
              <th>Account</th>
              <th>Opening Date</th>
              <th>Closing Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {client.platforms.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{item.id}</td>
                  <td>{PLATFORM_TYPES.getLabel(item.platform)}</td>
                  <td>{item.accountNumber}</td>
                  <td>{item.openingDate ? format(new Date(item.openingDate), STANDARD_DATE) : 'N/A'}</td>
                  <td>{item.closingDate ? format(new Date(item.closingDate), STANDARD_DATE) : 'N/A'}</td>
                  <td>
                    <ButtonGroup>
                      <Button color="primary" size="sm" tag={Link} to={`${url}/${item.id}`}>
                        <i className="far fa-edit" /> Edit
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {client.platforms.size === 0 && (
        <CardBody>
          <span>No Accounts connected</span>
        </CardBody>
      )}

      <CardFooter>
        <Button color="success" tag={Link} to={`${url}/create_account`}>
          <i className="fas fa-plus pr-2"></i>Add Platform Account
        </Button>
      </CardFooter>
    </Card>
  );
}
