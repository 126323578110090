import noop from 'lodash/noop';
import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as managerApi from 'core/Api/admin/managerApi';

import * as actions from './actions'

export function* getManagerSaga({ payload: { managerId, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(managerApi.getManager, { managerId });
    yield put(actions.getManager.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getManager.error(e));
    yield call(onError);
  }
}

export function* getManagerLogsSaga({ payload: { managerId, filters, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(managerApi.getManagerLogs, { managerId, filters });
    yield put(actions.getManagerLogs.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getManagerLogs.error(e));
    yield call(onError);
  }
}

export function* suspendManagerSaga({ payload: { managerId, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(managerApi.suspendManager, { managerId });
    yield put(actions.getManager({ managerId }));
    yield call(onSuccess);
    yield call(toast.error, 'Manager suspended');
  } catch (e) {
    yield call(onError);
  }
}

export function* activateManagerSaga({ payload: { managerId, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(managerApi.activateManager, { managerId });
    yield put(actions.getManager({ managerId }));
    yield call(onSuccess);
    yield call(toast.success, 'Manager activated');
  } catch (e) {
    yield call(onError);
  }
}

export function* exportActivitySaga({ payload: { filters, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const response = yield call(managerApi.exportActivity, { filters });
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'activity_report.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    yield put(actions.exportActivity.error(e));
  }
}

export function* setManagerPasswordSaga({ payload: { managerId, values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(managerApi.setManagerPassword, { managerId, values });
    yield put(actions.getManager({ managerId }));
    yield call(onSuccess);
    yield call(toast.success, 'Manager password has been changed');
  } catch (e) {
    yield put(actions.setManagerPassword.error(e));
  }
}


export default [
  takeLatest(actions.getManager, getManagerSaga),
  takeLatest(actions.getManagerLogs, getManagerLogsSaga),
  takeLatest(actions.suspendManager, suspendManagerSaga),
  takeLatest(actions.activateManager, activateManagerSaga),
  takeLatest(actions.exportActivity, exportActivitySaga),
  takeLatest(actions.setManagerPassword, setManagerPasswordSaga)
];
