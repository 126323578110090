// @flow
import React from 'react';
import { List } from 'immutable';
import { Button, Form, FormGroup, ModalFooter, ModalBody, Row, Col } from 'reactstrap';

import { FieldInput, FieldPhoneInput, FieldSelect } from 'core/Components/Form';
import { onlyLatinLetters } from 'core/Components/Form/normalizers';

type UserFormProps = {
  isSubmitting: boolean,
  isValid: boolean,
  handleSubmit: (Object) => void,
  isManager: boolean,
  managerRolesOptions?: List<any>,
};

export default function UserForm(props: UserFormProps) {
  const { handleSubmit, isSubmitting, isValid, isManager, managerRolesOptions } = props;

  return (
    <Form role="form" onSubmit={handleSubmit} autoComplete="off">
      <ModalBody>
      <Row>
        <Col md={6}>
          <FormGroup>
            <FieldInput
              label="First Name *"
              name="firstName"
              disabled={isSubmitting}
              normalize={onlyLatinLetters}
              autoComplete="hash-87"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <FieldInput
              label="Last Name *"
              name="lastName"
              disabled={isSubmitting}
              normalize={onlyLatinLetters}
              autoComplete="hash-88"
            />
          </FormGroup>
        </Col>
      </Row>
      
      <Row>
        <Col md={6}>
          <FormGroup className="mr-xs-0">
            <FieldInput
              label="Email *"
              name="email"
              className="auth_input"
              disabled={isSubmitting}
              autoComplete="hash-89"
            />
          </FormGroup>
        </Col>


        {!isManager ? (
        <Col md={6}>
          <FormGroup>
            <FieldPhoneInput
              label="Phone Number *"
              name="phoneNumber"
              disabled={isSubmitting}
              autoComplete="hash-90"
            />
          </FormGroup>
        </Col>

        ) : (
        <Col md={12}>
          <FormGroup>
            <FieldSelect
              label="Roles *"
              name="roles"
              isMulti
              disabled={isSubmitting}
              autoComplete="hash-91"
              options={managerRolesOptions}
            />
          </FormGroup>
        </Col>
      
        )}

        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <FieldInput label="Password (min 8 symbols) *" type="password" name="password" disabled={isSubmitting} autoComplete="hash-92" />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <FieldInput
                label="Retype Password *"
                type="password"
                name="passwordConfirmation"
                disabled={isSubmitting}
                autoComplete="hash-93"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="success" type="submit" disabled={isSubmitting || !isValid}>
          <i className="fas fa-user-plus pr-2"/>
          {isManager ? 'CREATE MANAGER' : 'CREATE USER'}
        </Button>
      </ModalFooter>
    </Form>
  );
}
