import { createSelector } from 'reselect';

const selectManagerCardSpace = (state) => state.get('managerCard');

export const selectManager = createSelector(
  selectManagerCardSpace,
  (managerCard) => managerCard.get('manager')
);

export const selectManagerLogs = createSelector(
  selectManagerCardSpace,
  (managerCard) => managerCard.get('managerLogs')
);
