import React from 'react'
import { Button, Card, CardHeader, CardBody, FormGroup, InputGroup, InputGroupAddon, Label, Table } from 'reactstrap'
import { subYears, addMonths } from 'date-fns'
import { FieldArray } from 'formik'

import { FieldCheckbox, FieldCountrySelect, FieldDatePicker, FieldInput, FieldSelect } from 'core/Components/Form'
import { alphanumWithSymbols } from 'core/Components/Form/normalizers'
import { CLIENT_TYPE, CONTACT_TYPES, TIN_TYPES, CORPORATE_POSITIONS, PERSON_TITLES } from 'core/Models/Enums'

import styles from './styles.module.scss'

const maxBirthDate = subYears(new Date(), 18)
const minPassportExpiryDate = addMonths(new Date(), 3)
const maxCompanyIncorporationDate = new Date()

type Props = {
  clientType: string,
  fieldsDisabled: boolean,
  values: any,
}

export default function ClientInformation(props: Props) {
  const { clientType, fieldsDisabled, values } = props

  return (
    <CardBody>
      <Card>
        <CardHeader className="blue-background">
          <h2 className="mb-0">Client Information</h2>
        </CardHeader>

        <Table striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: 300 }}>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {clientType === CLIENT_TYPE.CORPORATE ? (
              <tr>
                <td>Capacity</td>
                <td>
                  <FieldSelect
                    name="client.position"
                    options={CORPORATE_POSITIONS.selectOptions}
                    disabled={fieldsDisabled}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td>Full Name</td>
              <td>
                <InputGroup>
                  <InputGroupAddon className="title_prepend" addonType="prepend">
                    <FieldSelect
                      type="text"
                      name="client.title"
                      id="title"
                      options={PERSON_TITLES.selectOptions}
                      maxWidth={80}
                      disabled={fieldsDisabled}
                    />
                  </InputGroupAddon>
                  <FieldInput style={{ paddingLeft: 20 }} type="text" name="client.fullName" id="fullName" disabled />
                </InputGroup>
              </td>
            </tr>
            {values.client.type === CLIENT_TYPE.INDIVIDUAL ||
            values.client.position !== CORPORATE_POSITIONS.NONE_OF_ABOVE ? (
              <>
                <tr>
                  <td>Citizenship</td>
                  <td>
                    <FieldCountrySelect name="client.citizenship" disabled={fieldsDisabled} />
                  </td>
                </tr>
                <tr>
                  <td>Passport Number</td>
                  <td>
                    <FieldInput
                      name="client.passportNumber"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Passport Expiry Date</td>
                  <td>
                    <FieldDatePicker
                      name="client.passportExpiryDate"
                      id="passportExpiryDate"
                      minDate={minPassportExpiryDate}
                      showYearDropdown
                      scrollableYearDropdown
                      showMonthDropdown
                      yearDropdownItemNumber={15}
                      disabled={fieldsDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Date of Birth</td>
                  <td>
                    <FieldDatePicker
                      name="client.birthday"
                      maxDate={maxBirthDate}
                      showYearDropdown
                      scrollableYearDropdown
                      showMonthDropdown
                      yearDropdownItemNumber={60}
                      disabled={fieldsDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Country of Birth</td>
                  <td>
                    <FieldCountrySelect name="client.birthdayCountry" disabled={fieldsDisabled} />
                  </td>
                </tr>
                <tr>
                  <td>City of Birth</td>
                  <td>
                    <FieldInput name="client.birthdayCity" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
                <tr>
                  <td>Residential Address, line 1</td>
                  <td>
                    <FieldInput name="client.address" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
                <tr>
                  <td>Residential Address, City</td>
                  <td>
                    <FieldInput name="client.addressCity" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
                <tr>
                  <td>Residential Address, State</td>
                  <td>
                    <FieldInput name="client.addressState" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
                <tr>
                  <td>Residential Address, Country</td>
                  <td>
                    <FieldCountrySelect name="client.residence" disabled={fieldsDisabled} />
                  </td>
                </tr>
                <tr>
                  <td>Residential Address, Postal Code</td>
                  <td>
                    <FieldInput
                      name="client.addressPostalCode"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
              </>
            ) : null}
            <tr>
              <td>Where did you first hear about our services?</td>
              <td>
                <FieldInput
                  name="client.recommendedBy"
                  placeholder="Details, Name, Date (if possible)"
                  disabled={fieldsDisabled}
                  normalize={alphanumWithSymbols}
                />
              </td>
            </tr>
            <tr>
              <td>Personal TIN</td>
              <td>
                <FieldArray
                  name="client.tins"
                  render={(arrayHelpers) => (
                    <>
                      {values.client.tins.map((tin, idx) => (
                        <InputGroup key={`tins_row_${idx}`} style={{ marginBottom: '15px' }}>
                          <InputGroupAddon className="tins_prepend" addonType="prepend">
                            <FieldSelect
                              name={`client.tins.${idx}.type`}
                              options={TIN_TYPES.selectOptions}
                              maxWidth={120}
                              disabled={fieldsDisabled}
                            />
                          </InputGroupAddon>
                          <FieldInput name={`client.tins.${idx}.tin`} disabled={fieldsDisabled} required />

                          <InputGroupAddon addonType="append" className="tins_append">
                            <FieldCountrySelect
                              placeholder="Country to which TIN applies *"
                              name={`client.tins.${idx}.country`}
                              id={`client.tins.${idx}.country`}
                              disabled={fieldsDisabled}
                            />
                          </InputGroupAddon>

                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              size="sm"
                              color="simple"
                              onClick={() => arrayHelpers.remove(idx)}
                              className={styles.btnRemoveContact}
                              disabled={fieldsDisabled}
                            >
                              <i className="far fa-trash-alt px-1" />
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      ))}
                      {values.client.tins.length <= 4 ? (
                        <Button
                          type="button"
                          size="sm"
                          color="primary"
                          style={{ marginTop: '5px' }}
                          onClick={() =>
                            arrayHelpers.push({ type: TIN_TYPES.TIN, tin: '000', country: 'United States' })
                          }
                          disabled={fieldsDisabled}
                        >
                          <i className="fas fa-plus pr-2" />
                          Add TIN
                        </Button>
                      ) : null}
                    </>
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>Contact Information</td>
              <td>
                <FieldArray
                  name="client.contacts"
                  render={(arrayHelpers) => (
                    <>
                      {values.client.contacts.map((contact, idx) => (
                        <InputGroup key={`contacts_row_${idx}`} style={{ marginBottom: '15px' }}>
                          <InputGroupAddon className="contacts_prepend" addonType="prepend">
                            <FieldSelect
                              name={`client.contacts.${idx}.type`}
                              options={CONTACT_TYPES.selectOptions}
                              maxWidth={120}
                              disabled={fieldsDisabled || idx === 0}
                            />
                          </InputGroupAddon>
                          <FieldInput name={`client.contacts.${idx}.value`} disabled={fieldsDisabled || idx === 0} />
                          {idx > 0 ? (
                            <>
                              <InputGroupAddon addonType="append">
                                <Button
                                  type="button"
                                  size="sm"
                                  color="simple"
                                  onClick={() => arrayHelpers.remove(idx)}
                                  className={styles.btnRemoveContact}
                                  disabled={fieldsDisabled}
                                >
                                  <i className="ni ni-fat-remove" style={{ paddingTop: '3px', fontSize: '20px' }} />
                                </Button>
                              </InputGroupAddon>
                              <FormGroup check>
                                <Label check className="muted_label mt-md-2 mb-3" htmlFor={`contacts.${idx}.isPrimary`}>
                                  <FieldCheckbox
                                    name={`client.contacts.${idx}.isPrimary`}
                                    id={`client.contacts.${idx}.isPrimary`}
                                    disabled={fieldsDisabled || idx === 0}
                                  />{' '}
                                  Primary
                                </Label>
                              </FormGroup>
                            </>
                          ) : null}
                        </InputGroup>
                      ))}
                      {values.client.contacts.length <= 4 ? (
                        <Button
                          type="button"
                          size="sm"
                          color="primary"
                          style={{ marginTop: '5px' }}
                          onClick={() => arrayHelpers.push({ type: CONTACT_TYPES.PHONE, value: '', isPrimary: false })}
                          disabled={fieldsDisabled}
                        >
                          <i className="fas fa-plus pr-2" />
                          Add contact
                        </Button>
                      ) : null}
                    </>
                  )}
                />
              </td>
            </tr>

            {clientType === CLIENT_TYPE.INDIVIDUAL ? (
              <>
                <tr>
                  <td>Employer Company Name</td>
                  <td>
                    <FieldInput name="client.employerName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
                <tr>
                  <td>Occupation</td>
                  <td>
                    <FieldInput name="client.occupation" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Full Legal Name</td>
                  <td>
                    <FieldInput name="client.companyName" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
                  </td>
                </tr>
                <tr>
                  <td>Date of Incorporation</td>
                  <td>
                    <FieldDatePicker
                      name="client.companyIncorporationDate"
                      disabled={fieldsDisabled}
                      maxDate={maxCompanyIncorporationDate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Country of Incorporation</td>
                  <td>
                    <FieldCountrySelect
                      name="client.companyIncorporationCountry"
                      id="companyIncorporationCountry"
                      disabled={fieldsDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Registration Number</td>
                  <td>
                    <FieldInput
                      name="client.companyRegnumber"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Description of Business</td>
                  <td>
                    <FieldInput
                      name="client.companyDescription"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Regulator Name</td>
                  <td>
                    <FieldInput
                      name="client.companyRegulatorName"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Company Address, Line 1</td>
                  <td>
                    <FieldInput
                      name="client.companyAddress"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Company Address, Street</td>
                  <td>
                    <FieldInput
                      name="client.companyAddressCity"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Company Address, State</td>
                  <td>
                    <FieldInput
                      name="client.companyAddressState"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Company Address, Country</td>
                  <td>
                    <FieldCountrySelect
                      name="client.companyAddressCountry"
                      id="companyAddressCountry"
                      disabled={fieldsDisabled}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Company Address, Postal Code</td>
                  <td>
                    <FieldInput
                      name="client.companyAddressPostalCode"
                      disabled={fieldsDisabled}
                      normalize={alphanumWithSymbols}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Company TIN</td>
                  <td>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon className="tins_prepend" addonType="prepend">
                          <FieldSelect
                            name="client.companyTinType"
                            placeholder="Type"
                            options={TIN_TYPES.selectOptions}
                            maxWidth={180}
                            disabled={fieldsDisabled}
                          />
                        </InputGroupAddon>

                        <FieldInput
                          placeholder="Company TIN *"
                          maxLength={50}
                          name="client.companyTin"
                          autoComplete={`hash-2352`}
                          disabled={fieldsDisabled}
                        />

                        <InputGroupAddon addonType="append" className="tins_append">
                          <FieldCountrySelect
                            placeholder="Country to which TIN applies *"
                            name="client.companyTinCountry"
                            id="client.companyTinCountry"
                            disabled={fieldsDisabled}
                          />
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Card>
    </CardBody>
  )
}
