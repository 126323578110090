import type { ClientType } from 'core/Models/Client';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardHeader } from 'reactstrap';
import type { UserType } from 'core/Models/User';

import PlatformAccountForm from '../../../../../Components/PlatformAccountForm';

import * as actions from '../actions';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
};

export default function BankAccountPage(props: Props) {
  const { userId, client } = props;
  const { accountId } = useParams();

  const dispatch = useDispatch();

  const createPlatformAccount = ({ values, onSuccess, onError }) => {
    const success = () => {
      window.location.replace(`/users/${userId}/platforms`);
    };
    dispatch(actions.createPlatformAccount({ userId, values, onSuccess: success, onError }));
  };

  const updatePlatformAccount = ({ values, onSuccess, onError }) => {
    const success = () => {
      window.location.replace(`/users/${userId}/platforms`);
    };
    dispatch(actions.updatePlatformAccount({ userId, accountId, values, onSuccess: success, onError }));
  };

  const submitForm = accountId ? updatePlatformAccount : createPlatformAccount;

  const initialValues = accountId
    ? client
        .getIn(['platforms'])
        .find((a) => a.id === parseInt(accountId, 10))
        ?.toJS() ?? {}
    : {};

  // omit id prop
  const { id, ...restInitialValues } = initialValues;

  return (
    <Card>
      <CardHeader className="blue-background" tag="h2">
        {accountId ? `Platform Account #${accountId}` : 'New Platform Account'}
      </CardHeader>
      <PlatformAccountForm submitForm={submitForm} initialValues={restInitialValues} />
    </Card>
  );
}
