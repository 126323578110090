import { List } from 'immutable';
import { createSelector } from 'reselect';

const selectUsersSpace = (state) => state.get('users');

export const selectUsers = createSelector(
  selectUsersSpace,
  (users) => users.get('users', List())
);

export const selectUsersTotalCount = createSelector(
  selectUsersSpace,
  (users) => users.get('totalCount', 0)
);
