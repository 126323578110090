import React, { useEffect, useState } from 'react';
import { Button, CardBody, CardFooter, ListGroupItem, ListGroup, Card, CardHeader, Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';
import type { ClientType } from 'core/Models/Client';


import Pagination from '../../../../../Components/Pagination';

import UserNoteForm from './UserNoteForm';

import * as actions from '../actions';
import { selectUserNotes } from '../selectors';

type Props = {
  client: ClientType,
  userId: number,
};

export default function TabNotes(props: Props) {
  const { userId } = props;

  const dispatch = useDispatch();
  const deleteNote = (noteId) => dispatch(actions.deleteUserNote({ userId, noteId }));

  const userNotes = useSelector(selectUserNotes);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [page, setPage] = useState(0);
  const limit = 10;
  const skip = page * limit;
  const pagesCount = Math.ceil(userNotes.get('totalCount') / limit);

  useEffect(() => {
    dispatch(actions.getUserNotes({ userId, filters: { limit, skip } }));
  }, [userId, dispatch, skip, limit]);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <UserNoteForm userId={userId} closeModal={closeModal} />
      </Modal>
      <CardBody>
        <Card>
          <CardHeader className="d-flex blue-background justify-content-between">
            <h2 className="mr-auto mb-0">Notes</h2>
            <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
          </CardHeader>
          <ListGroup>
            {userNotes.get('notes').map((note, idx) => (
              <ListGroupItem key={idx} color={(note.type === 'INTERNAL') ? 'internal' : 'public'}>
                <p style={{marginBottom: '5px'}}>{note.content}</p>
                <small style={{color: 'grey'}}>
                  Added by <Link to={'/managers/' + note.submitterId}>{note.submitterName}</Link> on{' '}
                  {format(new Date(note.createdAt), STANDARD_TIME_DATE)} ({note.type.toLowerCase()} note  #{note.id}){'. '}
                  <a href="#delete" onClick={() => deleteNote(note.id)}>Delete Note</a>
                </small>
                
              </ListGroupItem>
            ))}
          </ListGroup>
          {userNotes.get('notes').size === 0 && <CardBody>No notes for this user</CardBody>}
          <CardFooter className="d-flex justify-content-between align-items-center">
            <Button color="success" onClick={openModal}>
              <i className="fas fa-plus pr-2"></i> Add Note
            </Button>
            <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
          </CardFooter>
        </Card>
      </CardBody>
    </>
  );
}
