import type { ClientType } from 'core/Models/Client'
import React from 'react'
import { format } from 'date-fns'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Card, CardHeader, CardBody, CardFooter, Table, ListGroupItem, ListGroup, Button } from 'reactstrap'
import type { UserType } from 'core/Models/User'
import * as actions from '../actions'
import { CLIENT_TYPE } from 'core/Models/Enums'
import { STANDARD_DATE } from 'core/Utils/dateUtils'

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
}

export default function DueDiligencePage(props: Props) {
  const { client } = props
  const { formId } = useParams()

  const dispatch = useDispatch()

  const form =
    client
      .getIn(['ddl'])
      .find((a) => a.id === parseInt(formId, 10))
      ?.toJS() ?? {}

  const downloadDocument = ({ id, name }) => {
    dispatch(actions.downloadDdlDocument({ id, name }))
  }

  const acceptForm = () => {
    dispatch(actions.acceptDdlForm({ id: form.id }))
  }
  return (
    <Card>
      <CardHeader className="blue-background" tag="h2">
        Due Diligence Form DD{formId}
      </CardHeader>

      <Table bordered responsive>
        <thead>
          <tr>
            <th scope="row">Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Form Type</th>
            <td>{CLIENT_TYPE.getLabel(client.type)} Form</td>
          </tr>
          <tr>
            <th scope="row">{client.type === 'INDIVIDUAL' ? <span>Full Name</span> : <span>Company Name</span>}</th>
            <td>{form.fullName}</td>
          </tr>
          <tr>
            <th scope="row">
              {client.type === 'INDIVIDUAL' ? <span>Residence Address</span> : <span>Registered Address</span>}
            </th>
            <td>{form.address}</td>
          </tr>
          <tr>
            <th scope="row">Operating Address</th>
            <td>{form.operatingAddress ?? 'N/A'}</td>
          </tr>
          <tr>
            <th scope="row">Passport Number</th>
            <td>{form.passportNumber ?? 'N/A'}</td>
          </tr>
          <tr>
            <th scope="row">Passport Expiry Date</th>
            <td> {form.passportExpiryDate ? format(new Date(form.passportExpiryDate), STANDARD_DATE) : 'N/A'}</td>
          </tr>
          <tr>
            <th scope="row">Citizenship</th>
            <td>{form.passportCountry ?? 'N/A'}</td>
          </tr>
          {form.tins
            ? form.tins.map((tin, idx) => (
                <tr key={`tax_number_${idx}`}>
                  <th>Taxpayer Identification Number #{idx + 1}</th>
                  <td>
                    {form.tins[idx].type} {form.tins[idx].tin} ({form.tins[idx].country})
                  </td>
                </tr>
              ))
            : null}

          <tr>
            <th scope="row">TIN Comment</th>
            <td> {form.description ?? 'N/A'}</td>
          </tr>
        </tbody>
      </Table>

      <CardHeader className="blue-background" tag="h2">
        Controlling Persons
      </CardHeader>

      {form.persons ? (
        form.persons.map((person, idx) => (
          <div
            className="pt-3 pb-2 px-4 mx-2 my-2"
            style={{ background: '#fafafb', boxShadow: '2px 2px 4px #e7ecf7', borderRadius: 12 }}
            key={`person_${idx}`}
          >
            <p className="mb-3" style={{ fontSize: 17, fontWeight: 'bold' }}>
              Controlling Person #{idx + 1}
            </p>
            <ul style={{ paddingLeft: 25 }}>
              <li className="mb-2">
                <b>CP Type:</b> {person.type}
              </li>
              <li className="mb-2">
                <b>Full Name:</b> {person.name}
              </li>
              <li className="mb-2">
                <b>Address:</b> {person.address}
              </li>
              <li className="mb-2">
                <b>Tax Number:</b> {person.tinType} {person.tin} ({person.tinCountry})
              </li>
              <li className="mb-2">
                <b>Passport Number:</b> {person.passportNumber}
              </li>
              <li className="mb-2">
                <b>Passport Expiration:</b>{' '}
                {person.passportExpiryDate ? format(new Date(person.passportExpiryDate), STANDARD_DATE) : 'N/A'}
              </li>
            </ul>
          </div>
        ))
      ) : (
        <CardBody>No controlling persons</CardBody>
      )}

      <CardHeader className="blue-background" tag="h2">
        Attached Documents
      </CardHeader>
      <ListGroup>
        {form.documents ? (
          form.documents.map((doc, idx) => (
            <ListGroupItem
              tag="button"
              action
              onClick={() => downloadDocument({ id: doc.id, name: doc.name })}
              key={`tax_number_${idx}`}
            >
              #{doc.id} {doc.name} — Download
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem disabled tag="a" href="#" action>
            No attached documents
          </ListGroupItem>
        )}
      </ListGroup>

      <CardFooter>
        <Button color="success" onClick={acceptForm} disabled={Boolean(form.acceptedAt)}>
          Accept Form
        </Button>
      </CardFooter>
    </Card>
  )
}
