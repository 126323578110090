import { createApiAction } from 'core/Utils/reduxUtils'

export const getUser = createApiAction('USER_CARD/GET_USER')
export const updateUser = createApiAction('USER_CARD/UPDATE_USER')

export const getUserLogs = createApiAction('USER_CARD/GET_USER_LOGS')

export const suspendUser = createApiAction('USER_CARD/SUSPEND_USER')
export const activateUser = createApiAction('USER_CARD/ACTIVATE_USER')

export const hideUser = createApiAction('USER_CARD/HIDE_USER')
export const showUser = createApiAction('USER_CARD/SHOW_USER')

export const activatePartner = createApiAction('USER_CARD/ACTIVATE_PARTNER')
export const deactivatePartner = createApiAction('USER_CARD/DEACTIVATE_PARTNER')

export const activateAccountManager = createApiAction('USER_CARD/ACTIVATE_ACCOUNT_MANAGER')
export const deactivateAccountManager = createApiAction('USER_CARD/DEACTIVATE_ACCOUNT_MANAGER')

export const fundUser = createApiAction('USER_CARD/FUND_USER')
export const convertToActive = createApiAction('USER_CARD/CONVERT_TO_ACTIVE')

export const verifyUser = createApiAction('USER_CARD/VERIFY_USER')
export const changeSectionVerifyStatus = createApiAction('USER_CARD/CHANGE_SECTION_VERIFY_STATUS')

export const createUserBankAccount = createApiAction('USER_CARD/CREATE_USER_BANK_ACCOUNT')
export const updateUserBankAccount = createApiAction('USER_CARD/UPDATE_USER_BANK_ACCOUNT')
export const deleteUserBankAccount = createApiAction('USER_CARD/DELETE_USER_BANK_ACCOUNT')

export const getUserNotes = createApiAction('USER_CARD/GET_USER_NOTES')
export const createUserNote = createApiAction('USER_CARD/CREATE_USER_NOTE')
export const deleteUserNote = createApiAction('USER_CARD/DELETE_USER_NOTE')

export const loadDocument = createApiAction('USER_CARD/LOAD_DOCUMENT')
export const downloadDocument = createApiAction('USER_CARD/DOWNLOAD_DOCUMENT')
export const deleteDocument = createApiAction('USER_CARD/DELETE_DOCUMENT')
export const verifyDocument = createApiAction('USER_CARD/VERIFY_DOCUMENT')

export const addCustomField = createApiAction('USER_CARD/ADD_CUSTOM_FIELD')
export const deleteCustomField = createApiAction('USER_CARD/DELETE_CUSTOM_FIELD')

export const changeRiskProfile = createApiAction('USER_CARD/CHANGE_RISK_PROFILE')
export const assignIeId = createApiAction('USER_CARD/ASSIGN_IE_ID')
export const assignRefId = createApiAction('USER_CARD/ASSIGN_REF_ID')
export const sendPassword = createApiAction('USER_CARD/SEND_PASSWORD')
export const unbindClient = createApiAction('USER_CARD/UNBIND_CLIENT')

export const syncCRM = createApiAction('USER_CARD/SYNC_CRM')
export const confirmEmail = createApiAction('USER_CARD/CONFIRM_EMAIL')

export const closeAccount = createApiAction('USER_CARD/CLOSE_ACCOUNT')
export const reopenAccount = createApiAction('USER_CARD/REOPEN_ACCOUNT')

export const changeName = createApiAction('USER_CARD/CHANGE_NAME')
export const changeEmail = createApiAction('USER_CARD/CHANGE_EMAIL')
export const changePhone = createApiAction('USER_CARD/CHANGE_PHONE')

export const createPlatformAccount = createApiAction('USER_CARD/CREATE_PLATFORM_ACCOUNT')
export const updatePlatformAccount = createApiAction('USER_CARD/UPDATE_PLATFORM_ACCOUNT')

export const downloadDdlDocument = createApiAction('USER_CARD/DOWNLOAD_DDL_DOCUMENT')
export const acceptDdlForm = createApiAction('USER_CARD/ACCEPT_DDL_FORM')
