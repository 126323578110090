import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalHeader } from 'reactstrap';
import { Formik } from 'formik';

import { managerValidationSchema } from 'core/Validation/userValidation';
import { Modal } from 'core/Components/Modal';

import UserForm from '../../../Components/UserForm';

import * as actions from './actions';
import { selectManagerRoles } from './selectors';

type Props = {
  isOpen: boolean,
  toggleModal: () => void,
};

export default function CreateManagerModal(props: Props) {
  const { isOpen, toggleModal } = props;

  const dispatch = useDispatch();
  const managerRoles = useSelector(selectManagerRoles);

  useEffect(() => {
    dispatch(actions.getManagerRoles());
  }, [dispatch]);

  const onSubmit = (values, { setSubmitting }) => {
    const { passwordConfirmation, phoneNumber, roles, ...restValues } = values;

    const data = {
      ...restValues,
      roles: roles.map((role) => ({ roleId: role })),
    };

    const onSuccess = () => {
      setSubmitting(false);
      toggleModal();
    };

    const onError = () => {
      setSubmitting(false);
    };

    dispatch(actions.createManager({ values: data, onSuccess, onError }));
  };

  const managerRolesOptions = managerRoles
    .toArray()
    .map((role) => ({ label: role.get('description'), value: role.get('id') }));

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader className="blue-background" tag="h2" toggle={toggleModal}>
        New manager
      </ModalHeader>
      {managerRolesOptions.length ? (
        <Formik
          autoComplete="off"
          onSubmit={onSubmit}
          render={(props) => <UserForm {...props} isManager managerRolesOptions={managerRolesOptions} />}
          validationSchema={managerValidationSchema}
        />
      ) : null}
    </Modal>
  );
}
