import { createSelector } from 'reselect';

const selectUserCardSpace = (state) => state.get('userCard');

export const selectUser = createSelector(
  selectUserCardSpace,
  (userCard) => userCard.get('user')
);

export const selectUserLogs = createSelector(
  selectUserCardSpace,
  (userCard) => userCard.get('userLogs')
);

export const selectUserNotes = createSelector(
  selectUserCardSpace,
  (userCard) => userCard.get('userNotes')
);

export const selectClient = createSelector(
  selectUserCardSpace,
  (userCard) => userCard.get('client')
);
