import React, {useState} from "react";
import DatePicker from "react-datepicker";
import { Col, Row, Label } from 'reactstrap';
import { addDays } from 'date-fns';

export function InputDateRange() {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));

  return (
    <Row>
      <Col>
        <Label className="text-muted">Start Date</Label>
        <DatePicker
          className="form-control"
          name="startDate"
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          showYearDropdown
          scrollableYearDropdown
          showMonthDropdown
          yearDropdownItemNumber={15}
          startDate={startDate}
          endDate={endDate}
        />
      </Col>
      <Col>
        <Label className="text-muted">End Date</Label>
        <DatePicker
          className="form-control"
          name="endDate"
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          showYearDropdown
          scrollableYearDropdown
          showMonthDropdown
          yearDropdownItemNumber={15}
          endDate={endDate}
          minDate={startDate}
        />
    </Col>
  </Row>

  );
}
