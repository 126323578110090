// @flow
import React, { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

import logo from 'core/Assets/img/brand/logo-white.svg';

type SidebarType = {
  routes: Array<{ path: string, icon: string, name: string, access: Array<string> }>,
};

export default function Sidebar(props: SidebarType) {
  const { routes } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  const toggleCollapse = () => setCollapseOpen(!collapseOpen);
  const closeCollapse = () => setCollapseOpen(false);

  return (
    <Navbar className="navbar-vertical fixed-left navbar-dark admin-sidebar" expand="md" id="sidenav-main">
      <Container fluid>

        <NavbarBrand tag={Link} to="/" className="pt-0">
          <img alt="Investors Europe" className="navbar-brand-img" src={logo} />
        </NavbarBrand>

        <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
          <span className="navbar-toggler-icon" />
        </button>

        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <h2>Investors Europe</h2>
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar className="sidebar">
            {routes.map(({ path, icon, name }, key) => (
              <NavItem key={`sidebar_nav_item_${key}`}>
                <NavLink to={path} tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active"><i className={icon} />{name}</NavLink>
              </NavItem>
            ))}
            <NavItem>
              <NavLink to="/logout" tag={NavLinkRRD} onClick={closeCollapse} activeClassName="active">
                <i className="fas fa-arrow-right pr-2"></i>
                Sign out
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
