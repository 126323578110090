import React from 'react'
import { ButtonToolbar, Button, ButtonGroup } from 'reactstrap'

import styles from './styles.module.scss'

type Props = {
  pagesCount: number,
  page: number,
  setPage: (page) => void,
  title?: string,
}

export default function Pagination(props: Props) {
  const { pagesCount, setPage, page } = props

  if (pagesCount) {
    return (
      <div className="d-flex" style={{ height: '30px' }}>
        <ButtonToolbar className={styles.paginationContainer}>
          <ButtonGroup>
            {[...new Array(pagesCount)].map((_, idx) => (
              <Button
                onClick={() => setPage(idx)}
                size="sm"
                style={{ marginRight: '3px' }}
                color={idx === page ? 'active' : 'simple'}
                key={`pagination_button_${idx}`}
              >
                {idx + 1}
              </Button>
            ))}
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }
  return null
}
