// @flow
import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardHeader, CardBody, CardFooter, Table } from 'reactstrap';
import type { ClientType } from 'core/Models/Client';
import AddFieldModal from './AddFieldModal'
import * as actions from '../actions';

type Props = {
  client: ClientType,
  userId: number
}

export default function TabCustomFields(props: Props) {
  const dispatch = useDispatch();
  const { client, userId } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const deleteCustomField = (fieldId) => dispatch(actions.deleteCustomField({ userId, fieldId }));

  return (
    <>
    <CardBody>
      <Card>
        <CardHeader tag="h2" className="blue-background">Custom Fields</CardHeader>
        <Table bordered hover responsive striped>

          <thead>
            <tr>
              <th style={{ width: 300 }}>Field</th>
              <th>Value</th>
              <th style={{ width: 50 }}>Actions</th>
            </tr>
          </thead>

          <tbody>
            {client.get('customFields').map((field, idx) => (
              <tr key={idx}>
                <th scope="row">{field.name}</th>
                <td>{field.value}</td>
                <td>
                  <Button size="sm" color="warning" onClick={() => deleteCustomField(field.id)}>
                    <i className="far fa-trash-alt pr-1" />Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
         {client.get('customFields').size === 0 && <CardBody>No custom fields</CardBody>}
      </Card>
      <AddFieldModal isOpen={isModalOpen} toggleModal={toggleModal} userId={userId} client={client} />

    </CardBody>
    <CardFooter>
      <Button color="success" onClick={toggleModal} disabled={!client.id}><i className="fas fa-plus pr-2" />Add Field</Button>
      {!client.id && <span className="pl-2 text-warning">Create a client before adding custom fields</span>}
    </CardFooter>
    </>
  );
}