import noop from 'lodash/noop';
import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as userApi from 'core/Api/admin/userApi';

import * as actions from './actions'

export function* getUsersListSaga({ payload: { filters = {}, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(userApi.getUsersList, { filters });
    yield put(actions.getUsersList.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getUsersList.error(e));
    yield call(onError);
  }
}

export function* createUserSaga({ payload: {  values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(userApi.createUser, { values });
    yield put(actions.getUsersList());
    yield call(onSuccess);
    yield call(toast.success, 'User created');
  } catch (e) {
    yield call(onError);
  }
}

export function* createUserWithClientSaga({ payload: {  values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(userApi.createUserWithClient, { values });
    yield call(onSuccess);
    yield call(toast.success, 'User with client created');
  } catch (e) {
    yield call(onError);
  }
}

export function* exportClientsSaga({ payload: { filter, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const response = yield call(userApi.exportClients, { filter });
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export_result.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    yield put(actions.exportClients.error(e));
  }
}

export function* importClientsSaga({ payload: { data, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const response = yield call(userApi.importClients, data);
    yield put(actions.getUsersList());
    yield call(onSuccess);
    yield call(toast.success, `Imported: ${response.count.success || 0}, errors: ${response.count.error || 0}`);
  } catch (e) {
    yield call(onError);
  }
}

export default [
  takeLatest(actions.getUsersList, getUsersListSaga),
  takeLatest(actions.createUser, createUserSaga),
  takeLatest(actions.createUserWithClient, createUserWithClientSaga),
  takeLatest(actions.exportClients, exportClientsSaga),
  takeLatest(actions.importClients, importClientsSaga),
];
