import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
  Button
} from 'reactstrap';

type Props = {
  userName: string,
}

export default function TopNavbar(props: Props) {
  const { userName, toggleSidebar, sidebar } = props;

  return (
    <>
      <Navbar className="navbar-top" expand="md" id="navbar-main">
        <Container fluid>
          
          <Button size="sm" className="d-none d-md-block" onClick={toggleSidebar}><i className="fas fa-toggle-on pr-2" />{sidebar ? 'Hide Sidebar' : 'Show Sidebar'}</Button>
    
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 d-none d-md-block">
                    <span className="mb-0 text-sm font-weight-bold"><i className="fas fa-user-cog pr-1"></i> {userName}</span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <Link to="/logout" className="dropdown-item">
                  <span><i className="fas fa-arrow-right pr-2"></i>Sign out</span>
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
