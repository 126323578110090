import noop from 'lodash/noop'
import { takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import * as userApi from 'core/Api/admin/userApi'

import * as actions from './actions'

export function* getUserSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.getUser, { userId })
    yield put(actions.getUser.success(result))
    yield call(onSuccess)
  } catch (e) {
    yield put(actions.getUser.error(e))
    yield call(onError)
  }
}

export function* getUserLogsSaga({ payload: { userId, filters, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.getUserLogs, { userId, filters })
    yield put(actions.getUserLogs.success(result))
    yield call(onSuccess)
  } catch (e) {
    yield put(actions.getUserLogs.error(e))
    yield call(onError)
  }
}

export function* updateUserSaga({ payload: { userId, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    const result = yield call(userApi.updateUser, { userId, values })
    yield put(actions.updateUser.success(result))
    yield call(onSuccess)
    yield call(toast.success, 'User updated successfully!')
  } catch (e) {
    yield put(actions.updateUser.error(e))
    yield call(onError)
  }
}

export function* suspendUserSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.suspendUser, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.error, 'User suspended')
  } catch (e) {
    yield call(onError)
  }
}

export function* activateUserSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.activateUser, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'User activated')
  } catch (e) {
    yield call(onError)
  }
}

export function* hideUserSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.hideUser, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.error, 'User is hidden')
  } catch (e) {
    yield call(onError)
  }
}

export function* showUserSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.showUser, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'User is visible')
  } catch (e) {
    yield call(onError)
  }
}

export function* activatePartnerSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.activatePartner, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, `Partner access for user ${userId} has been successfully added`)
  } catch (e) {
    yield call(onError)
  }
}

export function* deactivatePartnerSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.deactivatePartner, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.error, `Partner access for user ${userId} has been successfully deleted`)
  } catch (e) {
    yield call(onError)
  }
}

export function* activateAccountManagerSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.activateAccountManager, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, `Account Manager access for user ${userId} has been successfully granted`)
  } catch (e) {
    yield call(onError)
  }
}

export function* deactivateAccountManagerSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.deactivateAccountManager, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.error, `Account Manager access for user ${userId} has been successfully closed`)
  } catch (e) {
    yield call(onError)
  }
}

export function* fundUserSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.fundUser, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, `User successfully converted`)
  } catch (e) {
    yield call(onError)
  }
}

export function* convertToActiveSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.convertToActive, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, `User successfully converted`)
  } catch (e) {
    yield call(onError)
  }
}

export function* verifyUserSaga({ payload: { userId, value, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.verifyUser, { userId, value })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'User Verified')
  } catch (e) {
    yield call(onError)
  }
}

export function* changeSectionVerifyStatusSaga({ payload: { userId, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.changeSectionVerifyStatus, { userId, values })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Status Updated')
  } catch (e) {
    yield call(onError)
  }
}

export function* createUserBankAccountSaga({ payload: { userId, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.createUserBankAccount, { userId, values })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Bank account added')
  } catch (e) {
    yield call(onError)
  }
}

export function* updateUserBankAccountSaga({
  payload: { userId, accountId, values, onSuccess = noop, onError = noop } = {},
}) {
  try {
    yield call(userApi.updateUserBankAccount, { userId, accountId, values })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Bank account updated')
  } catch (e) {
    yield call(onError)
  }
}

export function* deleteUserBankAccountSaga({ payload: { userId, accountId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.deleteUserBankAccount, { userId, accountId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Bank account deleted')
  } catch (e) {
    yield call(onError)
  }
}

export function* getNotesSaga({ payload: { userId, filters = {} } = {} }) {
  try {
    const result = yield call(userApi.getUserNotes, { userId, filters })
    yield put(actions.getUserNotes.success(result))
  } catch (e) {
    yield put(actions.getUserNotes.error(e))
  }
}

export function* createNoteSaga({ payload: { userId, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.createUserNote, { userId, values })
    yield put(actions.getUserNotes({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Note created')
  } catch (e) {
    yield call(onError)
  }
}

export function* deleteNoteSaga({ payload: { userId, noteId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.deleteUserNote, { userId, noteId })
    yield put(actions.getUserNotes({ userId }))
    yield call(onSuccess)
    yield call(toast.error, 'Note deleted')
  } catch (e) {
    yield call(onError)
  }
}

export function* loadDocumentSaga({ payload }) {
  try {
    const userId = payload?.successful?.[0]?.response?.body?.user?.id
    if (userId) {
      yield put(actions.getUser({ userId }))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(actions.loadDocument.error(e))
  }
}

export function* downloadDocumentSaga({ payload: { documentId, userId, documentName } = {} }) {
  try {
    const response = yield call(userApi.downloadDocument, { documentId })
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', documentName)
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    yield put(actions.downloadDocument.error(e))
  }
}

export function* deleteDocumentSaga({ payload: { documentId, userId } }) {
  try {
    yield call(userApi.deleteDocument, { documentId })
    yield put(actions.getUser({ userId }))
    yield call(toast.success, 'Document deleted')
  } catch (e) {
    yield put(actions.deleteDocument.error(e))
  }
}

export function* verifyDocumentSaga({ payload: { documentId, userId } }) {
  try {
    yield call(userApi.verifyDocument, { documentId })
    yield put(actions.getUser({ userId }))
    yield call(toast.success, 'Document verified')
  } catch (e) {
    yield put(actions.verifyDocument.error(e))
  }
}

export function* addCustomFieldSaga({ payload: { userId, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.addCustomField, { userId, values })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Custom field saved')
  } catch (e) {
    yield call(onError)
    yield put(actions.verifyDocument.error(e))
  }
}

export function* deleteCustomFieldSaga({ payload: { userId, fieldId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.deleteCustomField, { userId, fieldId })
    yield put(actions.getUser({ userId }))
    yield call(toast.success, 'Custom field deleted')
  } catch (e) {
    yield put(actions.deleteCustomField.error(e))
  }
}

export function* changeRiskProfileSaga({ payload: { userId, riskProfile, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.changeRiskProfile, { userId, riskProfile })
    yield put(actions.getUser({ userId }))
    yield call(toast.success, 'Risk Profile changed')
  } catch (e) {
    yield put(actions.changeRiskProfile.error(e))
  }
}

export function* assignIeIdSaga({ payload: { userId, ieId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.assignIeId, { userId, ieId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'IE ID successfully assigned')
  } catch (e) {
    yield put(actions.assignIeId.error(e))
  }
}

export function* assignRefIdSaga({ payload: { userId, refId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.assignRefId, { userId, refId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Referral ID successfully assigned')
  } catch (e) {
    yield put(actions.assignIeId.error(e))
  }
}

export function* sendPasswordSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.sendPassword, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'New password successfully sent')
  } catch (e) {
    yield put(actions.sendPassword.error(e))
  }
}

export function* unbindClientSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.unbindClient, { userId })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Client successfully unbinded')
  } catch (e) {
    yield put(actions.unbindClient.error(e))
  }
}

export function* syncCRMSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.syncCRM, { userId })
    yield put(actions.getUser({ userId }))
    yield call(toast.success, 'Client successfully synchronized')
  } catch (e) {
    yield put(actions.syncCRM.error(e))
  }
}

export function* confirmEmailSaga({ payload: { userId, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.confirmEmail, { userId })
    yield put(actions.getUser({ userId }))
    yield call(toast.success, 'Email successfully confirmed')
  } catch (e) {
    yield put(actions.confirmEmail.error(e))
  }
}

export function* closeAccountSaga({ payload: { userId, closingDate, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.closeAccount, { userId, closingDate })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Account Closed Successfully')
  } catch (e) {
    yield put(actions.closeAccount.error(e))
  }
}

export function* reopenAccountSaga({ payload: { userId, status, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.reopenAccount, { userId, status })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Account Successfully Reopened')
  } catch (e) {
    yield put(actions.reopenAccount.error(e))
  }
}

export function* changeNameSaga({ payload: { userId, data, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.changeName, { userId, data })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Name Successfully Changed')
  } catch (e) {
    yield put(actions.changeName.error(e))
  }
}

export function* changeEmailSaga({ payload: { userId, email, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.changeEmail, { userId, email })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Email Successfully Changed')
  } catch (e) {
    yield put(actions.changeEmail.error(e))
  }
}

export function* changePhoneSaga({ payload: { userId, data, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.changePhone, { userId, data })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Phone Successfully Changed')
  } catch (e) {
    yield put(actions.changePhone.error(e))
  }
}

export function* createPlatformAccountSaga({ payload: { userId, values, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.createPlatformAccount, { userId, values })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Platform Account created')
  } catch (e) {
    yield call(onError)
  }
}

export function* updatePlatformAccountSaga({
  payload: { userId, accountId, values, onSuccess = noop, onError = noop } = {},
}) {
  try {
    yield call(userApi.updatePlatformAccount, { userId, accountId, values })
    yield put(actions.getUser({ userId }))
    yield call(onSuccess)
    yield call(toast.success, 'Platform Account updated')
  } catch (e) {
    yield call(onError)
  }
}

export function* downloadDdlDocumentSaga({ payload: { id, name } = {} }) {
  try {
    const response = yield call(userApi.downloadDdlDocument, id)
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    yield put(actions.downloadDocument.error(e))
  }
}

export function* acceptDdlFormSaga({ payload: { id, onSuccess = noop, onError = noop } = {} }) {
  try {
    yield call(userApi.acceptDdlForm, id)
    yield call(onSuccess)
    yield call(toast.success, `Form successfully accepted`)
  } catch (e) {
    yield call(onError)
  }
}

export default [
  takeLatest(actions.getUser, getUserSaga),
  takeLatest(actions.getUserLogs, getUserLogsSaga),
  takeLatest(actions.updateUser, updateUserSaga),
  takeLatest(actions.suspendUser, suspendUserSaga),
  takeLatest(actions.activateUser, activateUserSaga),
  takeLatest(actions.verifyUser, verifyUserSaga),
  takeLatest(actions.createUserBankAccount, createUserBankAccountSaga),
  takeLatest(actions.deleteUserBankAccount, deleteUserBankAccountSaga),
  takeLatest(actions.updateUserBankAccount, updateUserBankAccountSaga),
  takeLatest(actions.changeSectionVerifyStatus, changeSectionVerifyStatusSaga),
  takeLatest(actions.getUserNotes, getNotesSaga),
  takeLatest(actions.createUserNote, createNoteSaga),
  takeLatest(actions.deleteUserNote, deleteNoteSaga),
  takeLatest(actions.hideUser, hideUserSaga),
  takeLatest(actions.showUser, showUserSaga),
  takeLatest(actions.activatePartner, activatePartnerSaga),
  takeLatest(actions.deactivatePartner, deactivatePartnerSaga),
  takeLatest(actions.activateAccountManager, activateAccountManagerSaga),
  takeLatest(actions.deactivateAccountManager, deactivateAccountManagerSaga),
  takeLatest(actions.fundUser, fundUserSaga),
  takeLatest(actions.convertToActive, convertToActiveSaga),

  takeLatest(actions.loadDocument, loadDocumentSaga),
  takeLatest(actions.downloadDocument, downloadDocumentSaga),
  takeLatest(actions.deleteDocument, deleteDocumentSaga),
  takeLatest(actions.verifyDocument, verifyDocumentSaga),

  takeLatest(actions.addCustomField, addCustomFieldSaga),
  takeLatest(actions.deleteCustomField, deleteCustomFieldSaga),

  takeLatest(actions.changeRiskProfile, changeRiskProfileSaga),
  takeLatest(actions.assignIeId, assignIeIdSaga),
  takeLatest(actions.assignRefId, assignRefIdSaga),
  takeLatest(actions.sendPassword, sendPasswordSaga),
  takeLatest(actions.unbindClient, unbindClientSaga),

  takeLatest(actions.syncCRM, syncCRMSaga),
  takeLatest(actions.confirmEmail, confirmEmailSaga),

  takeLatest(actions.closeAccount, closeAccountSaga),
  takeLatest(actions.reopenAccount, reopenAccountSaga),

  takeLatest(actions.changeName, changeNameSaga),
  takeLatest(actions.changeEmail, changeEmailSaga),
  takeLatest(actions.changePhone, changePhoneSaga),

  takeLatest(actions.createPlatformAccount, createPlatformAccountSaga),
  takeLatest(actions.updatePlatformAccount, updatePlatformAccountSaga),

  takeLatest(actions.downloadDdlDocument, downloadDdlDocumentSaga),
  takeLatest(actions.acceptDdlForm, acceptDdlFormSaga),
]
