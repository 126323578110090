// @flow
import React, { useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Table,
  Modal,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Badge,
  FormGroup,
  Label,
  Alert,
} from 'reactstrap'
import { format } from 'date-fns'
import { STANDARD_DATE_TIME, STANDARD_DATE } from 'core/Utils/dateUtils'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { USER_STATUS } from 'core/Models/Enums'

import type { UserType } from 'core/Models/User'
import type { ClientType } from 'core/Models/Client'
import PhoneNumber from 'awesome-phonenumber'
import * as actions from './actions'

import DatePicker from 'react-datepicker'

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
}

export default function TabUser(props: Props) {
  const { userId, user, client } = props
  const dispatch = useDispatch()

  const suspendUser = () => dispatch(actions.suspendUser({ userId }))
  const activateUser = () => dispatch(actions.activateUser({ userId }))

  const hideUser = () => dispatch(actions.hideUser({ userId }))
  const showUser = () => dispatch(actions.showUser({ userId }))

  const confirmEmail = () => dispatch(actions.confirmEmail({ userId }))

  const activatePartner = () => dispatch(actions.activatePartner({ userId }))
  const deactivatePartner = () => dispatch(actions.deactivatePartner({ userId }))

  const activateManager = () => dispatch(actions.activateAccountManager({ userId }))
  const deactivateManager = () => dispatch(actions.deactivateAccountManager({ userId }))

  const [clientsModal, setClientsModal] = useState(false)
  const toggleClientsModal = () => setClientsModal(!clientsModal)

  const [historyModal, sethHistoryModal] = useState(false)
  const toggleHistoryModal = () => sethHistoryModal(!historyModal)

  const [fundModal, setFundModal] = useState(false)
  const toggleFundModal = () => setFundModal(!fundModal)
  const fundUser = () => {
    const onSuccess = () => {
      toggleFundModal()
    }
    dispatch(actions.fundUser({ userId, onSuccess }))
  }

  const [activeModal, setActiveModal] = useState(false)
  const toggleActiveModal = () => setActiveModal(!activeModal)
  const convertToActive = () => {
    const onSuccess = () => {
      toggleActiveModal()
    }
    dispatch(actions.convertToActive({ userId, onSuccess }))
  }

  const [nameModal, setNameModal] = useState(false)
  const toggleNameModal = () => setNameModal(!nameModal)
  const setName = (e) => {
    e.preventDefault()
    var data = {}
    const form = new FormData(e.currentTarget)
    for (var key of form.keys()) {
      data[key] = form.get(key)
    }
    const onSuccess = () => {
      toggleNameModal()
    }
    dispatch(actions.changeName({ userId, data, onSuccess }))
  }

  const [emailModal, setEmailModal] = useState(false)
  const toggleEmailModal = () => setEmailModal(!emailModal)
  const setEmail = (e) => {
    e.preventDefault()
    const email = e.target.email.value
    const onSuccess = () => {
      toggleEmailModal()
    }
    dispatch(actions.changeEmail({ userId, email, onSuccess }))
  }

  const [phoneModal, setPhoneModal] = useState(false)
  const togglePhoneModal = () => setPhoneModal(!phoneModal)
  const setPhone = (e) => {
    e.preventDefault()

    const phone = e.target.phone.value

    var pn = new PhoneNumber(phone)

    if (!pn.a.valid) {
      console.log('not valid')
      return
    }

    console.log(pn.getCountryCode())
    console.log(pn.getNumber('significant'))

    const data = {
      phoneCode: pn.getCountryCode(),
      phoneNumber: +pn.getNumber('significant'),
    }

    const onSuccess = () => {
      togglePhoneModal()
    }
    dispatch(actions.changePhone({ userId, data, onSuccess }))
  }

  const [idModal, setIdModal] = useState(false)
  const toggleIdModal = () => setIdModal(!idModal)

  const [refModal, setRefModal] = useState(false)
  const toggleRefModal = () => setRefModal(!refModal)

  const [closingModal, setClosingModal] = useState(false)
  const toggleClosingModal = () => setClosingModal(!closingModal)

  const [reopenModal, setReopenModal] = useState(false)
  const toggleReopenModal = () => setReopenModal(!reopenModal)

  const changeRiskProfile = (riskProfile) => dispatch(actions.changeRiskProfile({ userId, riskProfile }))

  const [sendPasswordModal, setSendPasswordModal] = useState(false)
  const toggleSendPasswordModal = () => setSendPasswordModal(!sendPasswordModal)
  const sendPassword = () => {
    const onSuccess = () => {
      toggleSendPasswordModal()
    }
    dispatch(actions.sendPassword({ userId, onSuccess }))
  }

  var pn = new PhoneNumber(`+${user.phoneCode}${user.phoneNumber}`)
  const phoneType = pn.isMobile() ? 'Mobile' : 'Landline'

  const assignIeId = (e) => {
    e.preventDefault()
    const onSuccess = () => {
      toggleIdModal()
    }
    dispatch(actions.assignIeId({ userId, ieId: e.target.ieId.value, onSuccess }))
  }

  const assignRefId = (e) => {
    e.preventDefault()
    const refId = parseInt(e.target.refId.value)
    const onSuccess = () => {
      toggleRefModal()
    }
    dispatch(actions.assignRefId({ userId, refId, onSuccess }))
  }

  const closeAccount = (e) => {
    e.preventDefault()
    const closingDate = e.target.closingDate.value
    const onSuccess = () => {
      toggleClosingModal()
    }
    dispatch(actions.closeAccount({ userId, closingDate, onSuccess }))
  }

  const [closingDate, setClosingDate] = useState(new Date())

  const handleClosingDateChange = (date) => {
    setClosingDate(date)
  }

  const reopenAccount = (e) => {
    e.preventDefault()
    const status = e.target.newStatus.value
    const onSuccess = () => {
      toggleReopenModal()
    }
    dispatch(actions.reopenAccount({ userId, status, onSuccess }))
  }

  const syncCRM = (e) => {
    e.preventDefault()
    dispatch(actions.syncCRM({ userId }))
  }

  const [unbindClientModal, setUnbindClientModal] = useState(false)
  const toggleUnbindClientModal = () => setUnbindClientModal(!unbindClientModal)
  const unbindClient = (e) => {
    const onSuccess = () => {
      toggleUnbindClientModal()
    }
    dispatch(actions.unbindClient({ userId, onSuccess }))
  }

  return (
    <CardBody>
      <Card>
        <CardHeader tag="h2" className="blue-background">
          User Information
        </CardHeader>
        <Table className="fixed-rows" responsive hover bordered striped>
          <thead>
            <tr>
              <th style={{ width: 200 }}>Field</th>
              <th>Value</th>
              <th style={{ width: 200 }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Full Name</th>
              <td>
                {user.firstName} {user.lastName}
              </td>
              <td>
                <Button color="simple" size="sm" onClick={toggleNameModal}>
                  <i className="far fa-edit pr-2" />
                  Change Name
                </Button>
              </td>
            </tr>

            <tr>
              <th scope="row">Email</th>
              <td>
                <a href={`mailto: ${pn.getNumber('international')}`}>
                  <span className="pr-2">
                    {user.email} {user.confirmedEmail}
                  </span>
                  {user.confirmedEmail ? (
                    <Badge color="success">Confirmed</Badge>
                  ) : (
                    <Badge color="warning">Unconfirmed</Badge>
                  )}
                </a>
              </td>
              <td>
                {!user.confirmedEmail && (
                  <Button color="success" size="sm" onClick={confirmEmail}>
                    <i className="far fa-check-circle pr-2"></i>CONFIRM EMAIL
                  </Button>
                )}

                <Button color="simple" size="sm" onClick={toggleEmailModal}>
                  <i className="far fa-edit pr-2"></i>CHANGE EMAIL
                </Button>
              </td>
            </tr>

            <tr>
              <th scope="row">Phone</th>
              <td>
                <a href={`tel: ${pn.getNumber('international')}`}>{pn.getNumber('international')}</a>{' '}
                <span className="text-muted">{phoneType}</span>
              </td>
              <td>
                <Button color="simple" size="sm" onClick={togglePhoneModal}>
                  <i className="far fa-edit pr-2"></i>CHANGE PHONE
                </Button>
              </td>
            </tr>

            {client.type && (
              <tr>
                <th scope="row">Account Type</th>
                <td>{client.type === 'INDIVIDUAL' ? 'Individual Client' : 'Corporate Client'}</td>
                <td></td>
              </tr>
            )}

            <tr>
              <th scope="row">Connected Client</th>
              <td>
                {user.clientId ? (
                  <span>
                    <Link to={`/users/${userId || ''}/client`}>
                      <i className="fas fa-user pr-2" />
                      {client.type === 'INDIVIDUAL' ? client.fullName : client.companyName}
                    </Link>
                  </span>
                ) : (
                  <span className="text-muted">No client</span>
                )}
              </td>
              <td>
                <ButtonGroup size="sm">
                  {!user.clientId ? (
                    <Link className="btn btn-success btn-sm" to={`/users/${userId || ''}/client`}>
                      <i className="fas fa-user-plus pr-2"></i>Bind Client
                    </Link>
                  ) : (
                    <Button color="warning" onClick={toggleUnbindClientModal}>
                      <i className="fas fa-user-minus pr-2" />
                      Unbind Client
                    </Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>

            <tr>
              <th scope="row">IE ID</th>
              <td>{client.ieId || <span className="text-muted">Not assigned</span>}</td>
              <td>
                <Button color="simple" disabled={!client.id} size="sm" onClick={toggleIdModal}>
                  <i className="far fa-edit pr-2" />
                  Assign ID
                </Button>
              </td>
            </tr>

            <tr>
              <th scope="row">Status</th>
              <td>
                <span
                  className={user.status === 'NOT_VERIFIED' || user.status === 'CLOSED' ? 'text-muted' : 'text-success'}
                  id="verificationDateTooltip"
                >
                  {user.status === 'NOT_VERIFIED' && <i className="far fa-circle pr-2" />}
                  {user.status === 'IN_VERIFICATION' && <i className="far fa-clock pr-2" />}
                  {user.status === 'VERIFIED' && <i className="far fa-check-circle pr-2" />}
                  {user.status === 'READY_TO_TRADE' && <i className="far fa-check-circle pr-2" />}
                  {user.status === 'ACTIVE_CLIENT' && <i className="far fa-check-circle pr-2" />}
                  {USER_STATUS.getLabel(user.status)}
                </span>
              </td>
              <td>
                {user.status === 'READY_TO_TRADE' && (
                  <Button size="sm" onClick={toggleActiveModal} color="simple">
                    <i className="fas fa-exchange-alt pr-2" />
                    Convert to Active Client
                  </Button>
                )}
                {user.status === 'VERIFIED' && (
                  <Button size="sm" onClick={toggleFundModal} color="simple">
                    <i className="fas fa-exchange-alt pr-2" />
                    Ready to Trade
                  </Button>
                )}
                {user.status === 'IN_VERIFICATION' && (
                  <Link className="btn btn-success btn-sm" to={`/users/${userId}/verification`}>
                    <i className="far fa-check-circle pr-2" />
                    To Verification
                  </Link>
                )}
              </td>
            </tr>

            {user.verifiedAt &&
              (user.status === 'VERIFIED' || user.status === 'READY_TO_TRADE' || user.status === 'ACTIVE_CLIENT') && (
                <tr>
                  <th scope="row">Verified At</th>
                  <td>{format(new Date(user.verifiedAt ?? 0), STANDARD_DATE_TIME)}</td>
                  <td></td>
                </tr>
              )}

            <tr>
              <th scope="row">Password Status</th>
              <td>
                {user.temporaryPwd ? (
                  <span className="text-muted">
                    <i className="far fa-clock pr-2" />
                    Temporary Password
                  </span>
                ) : (
                  <span className="text-success">
                    <i className="far fa-check-circle pr-2" />
                    Permanent Password
                  </span>
                )}
              </td>
              <td>
                <Button color="simple" size="sm" onClick={toggleSendPasswordModal}>
                  <i className="far fa-envelope pr-2" />
                  Send New Password
                </Button>
              </td>
            </tr>

            <tr>
              <th scope="row">Risk Profile</th>
              <td>
                {!user.clientId ? <span className="text-muted">Create a client to assign a risk profile</span> : <></>}
                {user.clientId ? !client.riskProfile && <span>Risk profile not assigned</span> : <></>}
                {client.riskProfile === 'LOW' && <span className="text-success">Low Risk</span>}
                {client.riskProfile === 'MEDIUM' && <span className="text-danger">Medium Risk</span>}
                {client.riskProfile === 'HIGH' && <span className="text-warning">High Risk</span>}
              </td>
              <td>
                <ButtonGroup size="sm">
                  <Button
                    color="primary"
                    disabled={!user.clientId}
                    onClick={() => changeRiskProfile('LOW')}
                    active={client.riskProfile === 'LOW'}
                  >
                    Low
                  </Button>
                  <Button
                    color="primary"
                    disabled={!user.clientId}
                    onClick={() => changeRiskProfile('MEDIUM')}
                    active={client.riskProfile === 'MEDIUM'}
                  >
                    Medium
                  </Button>
                  <Button
                    color="primary"
                    disabled={!user.clientId}
                    onClick={() => changeRiskProfile('HIGH')}
                    active={client.riskProfile === 'HIGH'}
                  >
                    High
                  </Button>
                </ButtonGroup>
              </td>
            </tr>

            <tr>
              <th scope="row">Registration Date</th>
              <td>
                {user.createdAt ? (
                  format(new Date(user.createdAt), STANDARD_DATE_TIME)
                ) : (
                  <span className="text-muted">Not assigned</span>
                )}
              </td>
              <td></td>
            </tr>

            <tr>
              <th scope="row">Closing Date</th>
              <td>
                {user.closingDate && user.status === 'CLOSED' ? (
                  format(new Date(user.closingDate), STANDARD_DATE)
                ) : (
                  <span className="text-muted">Not assigned</span>
                )}
              </td>
              <td>
                {user.status !== 'CLOSED' && (
                  <Button color="warning" size="sm" onClick={toggleClosingModal}>
                    <i className="far fa-window-close pr-2" />
                    Close Account
                  </Button>
                )}
                {user.closingDate && user.status === 'CLOSED' && (
                  <Button color="success" size="sm" onClick={toggleReopenModal}>
                    <i className="fas fa-retweet pr-2"></i>
                    Reopen Account
                  </Button>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Refferal ID</th>
              <td>
                <span>
                  {user.refId ? `Invited by user #${user.refId}` : <span className="text-muted">Not assigned</span>}
                </span>
              </td>
              <td>
                {!user.refId && (
                  <Button color="simple" size="sm" onClick={toggleRefModal}>
                    <i className="far fa-edit pr-2" />
                    Set Referral ID
                  </Button>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Created by Account Manager</th>
              <td>{user.amOwner ? <Link to={`/users/${user.amOwner}/user`}>User #{user.amOwner}</Link> : 'No'}</td>
              <td></td>
            </tr>

            <tr>
              <th scope="row">Bitrix24 CRM ID</th>
              <td>{user.crmId ? user.crmId : 'N/A'}</td>
              <td>
                <ButtonGroup>
                  <Button size="sm" color="primary" onClick={syncCRM}>
                    <i className="fas fa-sync pr-1" />
                    Sync
                  </Button>
                  {user.crmId && (
                    <a
                      className="btn btn-sm btn-simple"
                      href={`https://investorseurope.bitrix24.com/crm/contact/details/${user.crmId || '0'}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-external-link-alt pr-1" />
                      View in CRM
                    </a>
                  )}
                </ButtonGroup>
              </td>
            </tr>

            <tr>
              <th scope="row">Hidden</th>
              <td>{user.hidden ? 'Yes' : 'No'}</td>
              <td>
                {!user.hidden && (
                  <Button color="warning" size="sm" onClick={hideUser}>
                    <i className="far fa-eye-slash pr-2"></i>HIDE USER
                  </Button>
                )}
                {user.hidden && (
                  <Button color="success" size="sm" onClick={showUser}>
                    <i className="far fa-eye pr-2"></i>SHOW USER
                  </Button>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Suspended</th>
              <td>{user.suspended ? 'Yes' : 'No'}</td>
              <td>
                {!user.suspended && (
                  <Button color="warning" size="sm" onClick={suspendUser}>
                    <i className="fas fa-lock pr-2"></i>SUSPEND USER
                  </Button>
                )}
                {user.suspended && (
                  <Button color="success" size="sm" onClick={activateUser}>
                    <i className="fas fa-unlock pr-2"></i>ACTIVATE USER
                  </Button>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Account Manager</th>
              <td>{user.amAccess ? 'Yes' : 'No'}</td>
              <td>
                {!user.amAccess && (
                  <Button color="success" size="sm" onClick={activateManager}>
                    <i className="far fa-check-circle pr-2"></i>Grant Access
                  </Button>
                )}
                {user.amAccess && (
                  <Button color="warning" size="sm" onClick={deactivateManager}>
                    <i className="far fa-times-circle pr-2"></i>Close Access
                  </Button>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Partner Access</th>
              <td>{user.isPartner ? 'Yes' : 'No'}</td>
              <td>
                {!user.isPartner && (
                  <Button color="success" size="sm" onClick={activatePartner}>
                    <i className="far fa-check-circle pr-2"></i>Make Partner
                  </Button>
                )}
                {user.isPartner && (
                  <Button color="warning" size="sm" onClick={deactivatePartner}>
                    <i className="far fa-times-circle pr-2"></i>Unmake Partner
                  </Button>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">Linked Accounts</th>
              <td>
                <span className="text-muted">No linked accounts</span>
              </td>
              <td>
                <Button color="primary" size="sm" disabled>
                  Link New Account
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <Modal isOpen={clientsModal} toggle={toggleClientsModal}>
        <ModalHeader tag="h2" toggle={toggleClientsModal}>
          Available Clients for user #{user.id}
        </ModalHeader>

        <Table className="mb-0" striped bordered responsive>
          <thead>
            <tr>
              <th style={{ width: 30 }}>ID</th>
              <th>Client</th>
              <th style={{ width: 50 }}>Current</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#{client.id}</td>
              <td>{client.type === 'INDIVIDUAL' ? client.fullName : client.companyName}</td>
              <td className="text-success">
                <i className="far fa-check-circle pr-1" />
                Active
              </td>
            </tr>
          </tbody>
        </Table>
        <ModalFooter>
          <Button color="simple" onClick={toggleClientsModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={historyModal} toggle={toggleHistoryModal}>
        <ModalHeader tag="h2" toggle={toggleHistoryModal}>
          History of changing statuses
        </ModalHeader>
      </Modal>

      <Modal isOpen={fundModal} toggle={toggleFundModal}>
        <ModalHeader tag="h2" toggle={toggleFundModal}>
          Convert Confirmation
        </ModalHeader>
        <ModalBody>
          This user will be assigned the status "Ready to Trade".
          <br />
          Are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="simple" onClick={toggleFundModal}>
            Cancel
          </Button>
          <Button color="success" onClick={fundUser}>
            <i className="fas fa-exchange-alt pr-2" />
            Convert
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={activeModal} toggle={toggleActiveModal}>
        <ModalHeader tag="h2" toggle={toggleActiveModal}>
          Convert Confirmation
        </ModalHeader>
        <ModalBody>
          This user will be assigned the status "Active Client".
          <br />
          Are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="simple" onClick={toggleActiveModal}>
            Cancel
          </Button>
          <Button color="success" onClick={convertToActive}>
            <i className="fas fa-exchange-alt pr-2" />
            Convert
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={unbindClientModal} toggle={toggleUnbindClientModal}>
        <ModalHeader tag="h2" toggle={toggleUnbindClientModal}>
          Action Confirmation
        </ModalHeader>
        <ModalBody>
          <p className="text-warning">
            Client{' '}
            <b>
              #{client.id} {client.fullName}
            </b>{' '}
            for this user will be unbinded.
          </p>
          Are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color="simple" onClick={toggleUnbindClientModal}>
            Cancel
          </Button>
          <Button color="warning" onClick={unbindClient}>
            <i className="fas fa-user-minus pr-2" />
            Unbind Client
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={idModal} toggle={toggleIdModal}>
        <Form onSubmit={assignIeId}>
          <ModalHeader tag="h2" toggle={toggleIdModal}>
            Assign IE ID
          </ModalHeader>
          <ModalBody>
            <p>Enter new IE ID</p>
            <Input name="ieId" required minLength={2} maxLength={20} />
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleIdModal}>
              Cancel
            </Button>
            <Button color="success">
              <i className="fas fa-edit pr-2" />
              Assign
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={refModal} toggle={toggleRefModal}>
        <Form onSubmit={assignRefId}>
          <ModalHeader tag="h2" toggle={toggleRefModal}>
            Set Referral ID
          </ModalHeader>
          <ModalBody>
            <p>Enter Referral ID</p>
            <Input name="refId" type="number" required maxLength={6} />
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleRefModal}>
              Cancel
            </Button>
            <Button color="success">
              <i className="fas fa-edit pr-2" />
              Set ID
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={closingModal} toggle={toggleClosingModal}>
        <Form onSubmit={closeAccount}>
          <ModalHeader tag="h2" toggle={toggleClosingModal}>
            Close Account
          </ModalHeader>
          <ModalBody>
            <p>Enter Closing Date</p>
            <Input
              name="closingDate"
              tag={DatePicker}
              maxDate={new Date()}
              // dateFormat="dd.MM.yyyy"
              selected={closingDate}
              onChange={handleClosingDateChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleClosingModal}>
              Cancel
            </Button>
            <Button color="warning">
              <i className="far fa-window-close pr-2" />
              Close Account
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={reopenModal} toggle={toggleReopenModal}>
        <Form onSubmit={reopenAccount}>
          <ModalHeader tag="h2" toggle={toggleReopenModal}>
            Reopen Account
          </ModalHeader>
          <ModalBody>
            <p>Enter New Status for Reopening Account</p>
            <Input type="select" name="newStatus" id="newStatus">
              <option value="NOT_VERIFIED">Unverified</option>
              <option value="IN_VERIFICATION">'Application Submitted</option>
              <option value="VERIFIED">Ready to Fund</option>
              <option value="READY_TO_TRADE">Ready to Trade</option>
              <option value="ACTIVE_CLIENT">Active Client</option>
            </Input>
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleReopenModal}>
              Cancel
            </Button>
            <Button color="success">
              <i className="fas fa-retweet pr-2" />
              Reopen Account
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={sendPasswordModal} toggle={toggleSendPasswordModal}>
        <ModalHeader tag="h2" toggle={toggleSendPasswordModal}>
          Confirm action
        </ModalHeader>
        <ModalBody>
          <span>
            This client's password will be reset. A new password will be sent to the client by email to{' '}
            <b>{user.email}</b>.
          </span>
        </ModalBody>
        <ModalFooter>
          <Button color="simple" onClick={toggleSendPasswordModal}>
            Cancel
          </Button>
          <Button color="success" onClick={sendPassword}>
            <i className="far fa-envelope pr-2" />
            Send New Password
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={nameModal} toggle={toggleNameModal}>
        <ModalHeader tag="h2" toggle={toggleNameModal}>
          Change Client Name
        </ModalHeader>
        <Form onSubmit={setName}>
          <ModalBody>
            <Alert color="light">Warning! Client name will be changed.</Alert>
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input minLength={2} maxLength={30} name="firstName" id="firstName" placeholder={user.firstName} />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input minLength={2} maxLength={30} name="lastName" id="lastName" placeholder={user.lastName} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleNameModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              <i className="far fa-edit pr-2" />
              Change Name
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={emailModal} toggle={toggleEmailModal}>
        <ModalHeader tag="h2" toggle={toggleEmailModal}>
          Change Email
        </ModalHeader>
        <Form onSubmit={setEmail}>
          <ModalBody>
            <Alert color="light">Warning! Client email will be changed.</Alert>

            <Label for="email">Email</Label>
            <Input minLength={2} maxLength={30} name="email" id="email" placeholder={user.email} />
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleNameModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              <i className="far fa-edit pr-2" />
              Change Email
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={phoneModal} toggle={togglePhoneModal}>
        <ModalHeader tag="h2" toggle={togglePhoneModal}>
          Change Phone
        </ModalHeader>
        <Form onSubmit={setPhone}>
          <ModalBody>
            <Alert color="light">Warning! Client phone will be changed.</Alert>

            <Label for="phone">Phone (e.g. +79005005050)</Label>
            <Input
              minLength={2}
              maxLength={30}
              name="phone"
              id="phone"
              placeholder={'+' + user.phoneCode + ' ' + user.phoneNumber}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="simple" onClick={toggleNameModal}>
              Cancel
            </Button>
            <Button color="success" type="submit">
              <i className="far fa-edit pr-2" />
              Change Phone
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </CardBody>
  )
}
