import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { STANDARD_DATE_TIME } from 'core/Utils/dateUtils'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from './actions'
import { selectDashboard } from './selectors'

import { Link } from 'react-router-dom'
import { CardHeader, CardBody, Row, Col, ListGroup, ListGroupItem, Card, Label, FormGroup, Input } from 'reactstrap'
import { BarChart, Bar, XAxis, YAxis, Legend, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

export default function DashboardPage() {
  const dispatch = useDispatch()

  const [filter, setFilter] = useState('all')

  useEffect(() => {
    dispatch(actions.getDashboardInfo({ filter }))
  }, [dispatch, filter])

  const dashboard = useSelector(selectDashboard)

  const changeFilter = (e) => {
    setFilter(e.target.value)
  }

  return (
    <>
      <CardHeader tag="h1">Dashboard</CardHeader>
      <CardBody>
        <Row>
          <Col md={8}>
            <Card>
              <CardHeader tag="h2" className="blue-background">
                User Statistics
              </CardHeader>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={dashboard.graph}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar name="Registered" dataKey="registrations" fill="#a42725" />
                    <Bar name="Applications Submitted" dataKey="verifications" fill="#dcae05" />
                    <Bar name="Clients Approved" dataKey="approved" fill="#79a425" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardHeader className="blue-background" tag="h2">
                General statistics
              </CardHeader>
              <ListGroup>
                <ListGroupItem>
                  <b>Total:</b> {dashboard.general.counts.users}
                </ListGroupItem>
                <ListGroupItem>
                  <b>Verified:</b> {dashboard.general.counts.verifiedUsers}
                </ListGroupItem>
                <ListGroupItem>
                  <b>In Verification:</b> {dashboard.general.counts.inVerificationUsers}
                </ListGroupItem>
                <ListGroupItem>
                  <b>Unverified:</b> {dashboard.general.counts.unverifiedUsers}
                </ListGroupItem>
                <ListGroupItem>
                  <b>Suspended:</b> {dashboard.general.counts.suspendedUsers}
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={8}>
            <Card>
              <CardHeader className="blue-background" tag="h2">
                Recent events
              </CardHeader>
              <CardBody className="filter_tabs">
                <FormGroup check onChange={changeFilter}>
                  <b>Filters:</b>{' '}
                  <Label check>
                    <Input type="radio" name="radio1" value="all" defaultChecked /> All
                  </Label>
                  <Label check>
                    <Input type="radio" name="radio1" value="internal" /> Internal
                  </Label>
                  <Label check>
                    <Input type="radio" name="radio1" value="public" /> Public
                  </Label>
                  <Label check>
                    <Input type="radio" name="radio1" value="ddl" /> DDL
                  </Label>
                </FormGroup>
                <span>Records: {dashboard.logs.length}</span>
              </CardBody>
              <div style={{ height: 500, overflowY: 'scroll' }}>
                <ul className="logList">
                  {dashboard.logs.map((log, idx) => (
                    <li key={idx} className={log.submitterId ? 'logList_internal' : 'logList_public'}>
                      <span className="logList_date">{format(new Date(log.createdAt), STANDARD_DATE_TIME)}</span>{' '}
                      <Link
                        className="text-primary"
                        to={log.submitterId ? `/managers/${log.submitterId}/manager` : `/users/${log.userId}/user/`}
                      >
                        {log.event}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardHeader className="blue-background" tag="h2">
                Upcoming events
              </CardHeader>
              <div style={{ height: 500, overflowY: 'scroll' }}>
                <ListGroup>
                  {dashboard.upcomingEvents.map((event, idx) => (
                    <ListGroupItem key={idx} color={'warning'}>
                      <small>
                        {event.event} – {event.date}
                        <Link className="text-danger" to={`/users/${event.id}/user/`}>
                          <i className="fas fa-arrow-right pl-1" />
                        </Link>
                      </small>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </>
  )
}
