import { createSelector } from 'reselect';

const selectAppSpace = (state) => state.get('app');

export const selectAuthUser = createSelector(
  selectAppSpace,
  (app) => app.get('user')
);

export const selectAuthUserToken = createSelector(
  selectAppSpace,
  (app) => app.get('token')
);
