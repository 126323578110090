import { User } from 'core/Models/User';
import { listOf } from 'core/Utils/immutableUtils';
import { Map, List } from 'immutable';
import { createReducer } from 'core/Utils/reduxUtils';

import * as actions from './actions';

const initialState = Map({
  users: List(),
  totalCount: 0,
});

export default createReducer(
  {
    [actions.getUsersList.success]: (state, { data, totalCount }) =>
      state.set('users', listOf(User, data)).set('totalCount', totalCount),
  },
  initialState
);
