import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, ListGroup, ListGroupItem } from 'reactstrap';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';

import Pagination from '../../../../Components/Pagination';

import { selectManagerLogs } from './selectors';
import * as actions from './actions';

type Props = {
  managerId: number,
};

export default function TabHistory(props: Props) {
  const { managerId } = props;
  const dispatch = useDispatch();
  const managerLogs = useSelector(selectManagerLogs);

  const [page, setPage] = useState(0);
  const limit = 10;
  const skip = page * limit;
  const pagesCount = Math.ceil(managerLogs.get('totalCount') / limit);

  useEffect(() => {
    dispatch(actions.getManagerLogs({ managerId, filters: { limit, skip } }));
  }, [managerId, dispatch, skip, limit]);

  return (
    <CardBody>
      <Card>
        <CardHeader className="d-flex blue-background justify-content-between">
          <h2 className="mb-0 mr-auto">History</h2>
          <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
        </CardHeader>
        <ListGroup>
          {managerLogs.get('logs').map((log, idx) => (
            <ListGroupItem key={idx}>
              <p style={{marginBottom: '5px'}}>{log.event}</p>
              <small style={{color: 'grey'}}>{format(new Date(log.createdAt), STANDARD_TIME_DATE)}</small>
            </ListGroupItem>
          ))}
        </ListGroup>
        <CardFooter className="d-flex justify-content-end">
          <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
        </CardFooter>
      </Card>
    </CardBody>
  );
}
