import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer, cssTransition, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import 'core/Assets/scss/react/plugins/react-datepicker.scss';
import 'core/Assets/vendor/nucleo/css/nucleo.css';
import 'core/Assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'core/Assets/scss/bundle.scss';

import { PrivateRoute } from './Routes';
import configureStore from './configureStore';

import LoginPage from './Pages/LoginPage';
import LogoutPage from './Pages/LoginPage/LogoutPage';
import InnerPage from './Pages/InnerPage';

const store = configureStore();

toast.configure({ className: 'black-background' });

const Zoom = cssTransition({
  enter: 'flipInX',
  exit: 'flipOutX',
  duration: [1000, 1000],
  appendPosition: false
});

export default function() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/logout" component={LogoutPage} />
          <PrivateRoute path="/" component={InnerPage} />
        </Switch>
      </Router>
      <ToastContainer position="top-center" autoClose={3000} transition={Zoom} hideProgressBar={true} className="custom-toast" newestOnTop={true}/>
    </Provider>
  );
}
