import React from 'react';
import { Card, CardHeader, CardBody, Table } from 'reactstrap';

import { FieldCountrySelect, FieldInput, FieldSelect } from 'core/Components/Form';
import { alphanumWithSymbols, onlyNumbers } from 'core/Components/Form/normalizers';
import { INVESTMENT_OBJECTIVES, SOURCE_OF_FUNDS } from 'core/Models/Enums';

type Props = {
  fieldsDisabled: boolean,
  values: any,
};

export default function InvestmentObjectives(props: Props) {
  const { fieldsDisabled, values } = props;

  return (
    <CardBody>
      <Card>
        <CardHeader tag="h2" className="blue-background">Client Investment Objectives</CardHeader>
        <Table striped bordered>
          <thead>
            <tr>
              <th style={{ width: 300 }}>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Investment Objectives</td>
              <td>
                <FieldSelect
                  options={INVESTMENT_OBJECTIVES.selectOptions}
                  name="investmentObjectives.objectives"
                  disabled={fieldsDisabled}
                />
              </td>
            </tr>
            <tr>
              <td>Client’s Net Worth</td>
              <td>
                <FieldInput
                  name="investmentObjectives.networthAmount"
                  disabled={fieldsDisabled}
                  normalize={onlyNumbers}
                />
              </td>
            </tr>
            <tr>
              <td>Client’s Annual Net Income</td>
              <td>
                <FieldInput name="investmentObjectives.incomeAmount" disabled={fieldsDisabled} normalize={onlyNumbers} />
              </td>
            </tr>
            <tr>
              <td>Amount you intend to invest</td>
              <td>
                <FieldInput name="investmentObjectives.investAmount" disabled={fieldsDisabled} normalize={onlyNumbers} />
              </td>
            </tr>
            <tr>
              <td>The source of funds you are going to invest</td>
              <td>
                <FieldSelect
                  options={SOURCE_OF_FUNDS.selectOptions}
                  name="investmentObjectives.sourceOfFunds"
                  disabled={fieldsDisabled}
                />
              </td>
            </tr>
            {values.investmentObjectives.sourceOfFunds === SOURCE_OF_FUNDS.OTHER || values.investmentObjectives.sourceOfFunds === SOURCE_OF_FUNDS.OWNER_OF_BUSINESS ? (
              <tr>
                <td>Details</td>
                <td>
                  <FieldInput
                    name="investmentObjectives.sourceOfFundsDescription"
                    disabled={fieldsDisabled}
                    normalize={alphanumWithSymbols}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td>Tax Identification Number (TIN)</td>
              <td>
                <FieldInput name="investmentObjectives.tin" disabled={fieldsDisabled} normalize={alphanumWithSymbols} />
              </td>
            </tr>
            <tr>
              <td>Country to which TIN applies</td>
              <td>
                <FieldCountrySelect name="investmentObjectives.tinCountry" disabled={fieldsDisabled} />
              </td>
            </tr>

          </tbody>
        </Table>
      </Card>
    </CardBody>
  );
}
