// @flow
import { USER_ROLES, CLIENT_DOCUMENT_TYPE } from 'core/Models/Enums';
import React, { useState } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Table, ButtonGroup } from 'reactstrap';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { Document, Page } from 'react-pdf';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { apiRequest } from 'core/Api';
import type { ClientType } from 'core/Models/Client';
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';

import Pagination from '../../../../Components/Pagination';
import { selectAuthUserToken, selectAuthUser } from '../../../../Selectors';

import * as actions from './actions';

import { UploadButton } from 'core/Components/Form';

const ReactSwal = withReactContent(Swal);

function DocumentModal(props) {
  const { url, name, isPdf, isImg } = props;
  const [page, setPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);

  return (
    <Card className="px-0 py-0">
      <CardHeader tag="h2">Document Preview</CardHeader>

      {isPdf && (
        <>
          <div>
            <Document file={url} onLoadSuccess={({ numPages }) => setPagesCount(numPages)}>
              <Page pageNumber={page + 1} width={700} renderAnnotationLayer={false} />
            </Document>
          </div>
          <CardFooter>
            <div className="pb-3" style={{ overflowX: 'scroll', overflowY: 'hidden' }}>
              <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
            </div>
          </CardFooter>
        </>
      )}

      {isImg && <img alt={name} style={{ width: '100%' }} src={url} />}

      {!isPdf && !isImg && <CardBody>Preview not available for this file</CardBody>}
    </Card>
  );
}

function DocumentsList(props) {
  const { documents, showFile, deleteDocument, verifyDocument, downloadDocument } = props;

  const authUser = useSelector(selectAuthUser);

  return (
    <>
      {documents.size > 0 && (
        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <th width={50}>ID</th>
              <th>Name</th>
              <th>Submitted At</th>
              <th>Verified At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, idx) => {
              return (
                <tr key={idx}>
                  <td>{doc.id}</td>
                  <td>{doc.name}</td>
                  <td>{format(new Date(doc.createdAt), STANDARD_TIME_DATE)}</td>
                  <td>
                    {doc.verifiedAt ? (
                      <span>{format(new Date(doc.verifiedAt), STANDARD_TIME_DATE)}</span>
                    ) : (
                      <Button
                        color="success"
                        size="sm"
                        onClick={() => {
                          verifyDocument(doc.id);
                        }}
                      >
                        <i className="fas fa-check pr-1" />
                        Verify
                      </Button>
                    )}
                  </td>
                  <td>
                    <ButtonGroup>
                      <Button color="simple" size="sm" onClick={() => showFile({ id: doc.id, name: doc.name })}>
                        <i className="far fa-eye" /> Watch
                      </Button>
                      {authUser.hasRole([USER_ROLES.SUPERADMIN]) && (
                        <Button
                          color="simple"
                          size="sm"
                          onClick={() => downloadDocument({ id: doc.id, name: doc.name })}
                        >
                          <i className="far fa-arrow-alt-circle-down pr-1" />
                          Download
                        </Button>
                      )}
                      {authUser.hasRole([USER_ROLES.SUPERADMIN]) && (
                        <Button color="warning" size="sm" onClick={() => deleteDocument(doc.id)}>
                          <i className="far fa-trash-alt pr-1" />
                          Delete
                        </Button>
                      )}
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {documents.size === 0 && <CardBody>No Documents Uploaded</CardBody>}
    </>
  );
}

type Props = {
  client: ClientType,
  userId: number,
};

export default function TabDocuments(props: Props) {
  const { client, userId } = props;
  const clientId = client.id ?? '';

  const authUserToken = useSelector(selectAuthUserToken);

  const dispatch = useDispatch();
  const loadDocument = (payload) => dispatch(actions.loadDocument(payload));
  const deleteDocument = (documentId) => dispatch(actions.deleteDocument({ documentId, userId }));

  const verifyDocument = (documentId) => {
    dispatch(actions.verifyDocument({ documentId, userId }));
  };

  const downloadDocument = (doc) => {
    dispatch(actions.downloadDocument({ documentId: doc.id, userId, documentName: doc.name }));
  };

  const showFile = ({ id, name = '' }) => {
    name = name.toLowerCase();
    const isPdf = name.includes('.pdf');
    const isImg = name.includes('.png') || name.includes('.jpg') || name.includes('.jpeg');
    apiRequest
      .get(`documents/${id}`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${authUserToken}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        ReactSwal.fire({
          ...(isPdf
            ? { heightAuto: true, animation: false, width: 750 }
            : { heightAuto: true, animation: false, width: '65%' }),
          html: <DocumentModal url={url} name={name} isPdf={isPdf} isImg={isImg} />,
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
  };

  const documents = client.documents.filter((doc) => !doc.deleted);
  const clientDocuments = documents.filter((doc) => !doc.type || doc.type === CLIENT_DOCUMENT_TYPE.CLIENT);
  const googleDocuments = documents.filter((doc) => doc.type === CLIENT_DOCUMENT_TYPE.GOOGLE);
  const facebookDocuments = documents.filter((doc) => doc.type === CLIENT_DOCUMENT_TYPE.FACEBOOK);
  const worldDocuments = documents.filter((doc) => doc.type === CLIENT_DOCUMENT_TYPE.WORLD);
  const managerDocuments = documents.filter((doc) => doc.type === CLIENT_DOCUMENT_TYPE.MANAGER);

  return (
    <CardBody>
      <Card>
        <CardHeader className="blue-background" tag="h2">
          Client Documents
        </CardHeader>
        <DocumentsList
          documents={clientDocuments}
          showFile={showFile}
          deleteDocument={deleteDocument}
          verifyDocument={verifyDocument}
          downloadDocument={downloadDocument}
        />
        <CardFooter>
          <UploadButton
            size="sm"
            color="primary"
            userToken={authUserToken}
            apiPath={`documents/${clientId}/client`}
            onDocumentLoad={loadDocument}
          />
        </CardFooter>
      </Card>

      <Card className="mt-4">
        <CardHeader tag="h2" className="blue-background">
          Internal Documents
        </CardHeader>

        <DocumentsList
          documents={managerDocuments}
          showFile={showFile}
          deleteDocument={deleteDocument}
          verifyDocument={verifyDocument}
          downloadDocument={downloadDocument}
        />

        <CardFooter>
          <UploadButton
            size="sm"
            color="primary"
            userToken={authUserToken}
            mimeTypes={['image/*', 'application/*']}
            note="All applications files, up to 100MB"
            apiPath={`documents/${clientId}/manager`}
            onDocumentLoad={loadDocument}
          />
        </CardFooter>
      </Card>

      <Card className="mt-4">
        <CardHeader tag="h2" className="blue-background">
          Compliance screening: World Check
        </CardHeader>

        <DocumentsList
          documents={worldDocuments}
          showFile={showFile}
          deleteDocument={deleteDocument}
          verifyDocument={verifyDocument}
          downloadDocument={downloadDocument}
        />

        <CardFooter>
          <UploadButton
            size="sm"
            color="primary"
            userToken={authUserToken}
            apiPath={`documents/${clientId}/world`}
            mimeTypes={['image/*', 'application/*']}
            note="All applications files, up to 100MB"
            onDocumentLoad={loadDocument}
          />
        </CardFooter>
      </Card>

      <Card className="mt-4">
        <CardHeader tag="h2" className="blue-background">
          Compliance screening: Google Check
        </CardHeader>

        <DocumentsList
          documents={googleDocuments}
          showFile={showFile}
          deleteDocument={deleteDocument}
          verifyDocument={verifyDocument}
          downloadDocument={downloadDocument}
        />

        <CardFooter>
          <UploadButton
            size="sm"
            color="primary"
            userToken={authUserToken}
            mimeTypes={['image/*', 'application/*']}
            note="All applications files, up to 100MB"
            apiPath={`documents/${clientId}/google`}
            onDocumentLoad={loadDocument}
          />
        </CardFooter>
      </Card>

      <Card className="mt-4">
        <CardHeader tag="h2" className="blue-background">
          Compliance screening: Facebook Check
        </CardHeader>

        <DocumentsList
          documents={facebookDocuments}
          showFile={showFile}
          deleteDocument={deleteDocument}
          verifyDocument={verifyDocument}
          downloadDocument={downloadDocument}
        />

        <CardFooter>
          <UploadButton
            size="sm"
            color="primary"
            userToken={authUserToken}
            mimeTypes={['image/*', 'application/*']}
            note="All applications files, up to 100MB"
            apiPath={`documents/${clientId}/facebook`}
            onDocumentLoad={loadDocument}
          />
        </CardFooter>
      </Card>
    </CardBody>
  );
}
