import { Map, fromJS } from 'immutable';
import { createReducer } from 'core/Utils/reduxUtils';
import * as actions from './actions';

const initialState = Map({
  bitrixInfo: Map({
    users: 0,
    crmUsers: 0,
    totalUsers: 0,
  }),
});

export default createReducer(
  {
    [actions.bitrixGetInfo.success]: (state, payload) => state.set('bitrixInfo', fromJS(payload)),
  },
  initialState
);
