import { Map, fromJS } from 'immutable';

import { createReducer } from 'core/Utils/reduxUtils';
import { User } from 'core/Models/User';

import * as mainActions from './Actions';

const initialState = Map({
  user: new User(),
  token: undefined,
});

export default createReducer(
  {
    [mainActions.userLoggedIn]: (state, { user, token }) => state.set('token', token).set('user', new User(user)),
    [mainActions.userLoggedOut]: () => initialState,
    [mainActions.getCurrentUser.success]: (state, payload) => state.set('user', new User(payload)),
    [mainActions.updateCurrentUser]: (state, payload) => state.set('user', state.get('user').merge(fromJS(payload))),
  },
  initialState
);
