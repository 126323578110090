// @flow
import React, { useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import XLSX from 'xlsx';
import { useDispatch } from 'react-redux';

import { Modal } from 'core/Components/Modal';

import Grid from '../../../Components/Grid';

import * as actions from './actions';

type Props = {
  isOpen: boolean,
  toggleModal: () => void,
};

export default function ImportUsersModal(props: Props) {
  const { isOpen, toggleModal } = props;
  const [importData, setImportData] = useState();
  const [tableData, setTableData] = useState();
  const [tableSkip, setTableSkip] = useState(0);

  const dispatch = useDispatch();
  const importClients = () => {
    const onSuccess = () => {
      toggleModal();
    }
    dispatch(actions.importClients({ data: importData, onSuccess }));
  }

  const handleUpload = (e) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const result = {};
      workbook.SheetNames.forEach((sheetName) => {
        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        if (roa.length) result[sheetName] = roa;
      });
      const firstSheet = result[Object.keys(result)[0]];

      const columns = firstSheet[1];
      const users = firstSheet.slice(2);
      const transformedResult = users.map((r) => {
        const user = columns.slice(0, 5).reduce((acc, curr, index) => {
          return {
            ...acc,
            [curr]: r[index],
          };
        }, {});
        const client = columns.slice(5).reduce((acc, curr, index) => {
          return {
            ...acc,
            [curr]: r[index + 5],
          };
        }, {});

        return { user, client };
      });
      const tableColumns = columns.map((c) => ({ key: c, label: c.split(/(?=[A-Z])/).join(' ') }));
      const tableUsers = transformedResult.map((uc) => ({ ...uc.user, ...uc.client }));
      setTableData({ columns: tableColumns, data: tableUsers });
      setImportData(transformedResult);
    };
    reader.readAsArrayBuffer(file);
  };

  const limit = 10;

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="big-modal">
      <ModalHeader className="blue-background" tag="h2">
        Import clients
      </ModalHeader>
      <ModalBody>
        <p><a className="btn btn-sm btn-simple" href="https://static.investorseurope.com/v2/examples/example_import.xlsx" download>Download Import Sample</a></p>
        <input type="file" accept=".xlsx" onChange={handleUpload} />
        {tableData ? (
          <>
            <hr />
            <h2>Import Result</h2>
            <p>Please check all fields before importing</p>
            <Grid
              data={tableData.data.slice(tableSkip, tableSkip + limit)}
              columns={tableData.columns}
              limit={limit}
              totalCount={tableData.data.length}
              loadDataCallback={({ filters: { skip } }) => setTableSkip(skip)}
            />
            
          </>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="simple" onClick={toggleModal}>Cancel</Button>
        <Button color="success" onClick={importClients} disabled={!tableData}>IMPORT CLIENTS</Button>
      </ModalFooter>
    </Modal>
  );
}
