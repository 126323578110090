// @flow
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Collapse,
  Card,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import DatePicker from 'react-datepicker'

import { USER_STATUS } from 'core/Models/Enums'
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils'
import PhoneNumber from 'awesome-phonenumber'

import Grid from '../../../Components/Grid'
import CreateUserModal from './CreateUserModal'
import ImportUsersModal from './ImportUsersModal'

import { selectUsers, selectUsersTotalCount } from './selectors'

// Check Manager Role
import { USER_ROLES } from 'core/Models/Enums'
import { selectAuthUser } from '../../../Selectors'

import { InputDateRange } from './InputDateRange'
import * as actions from './actions'
import * as _ from 'lodash'

const columns = [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    key: 'firstName',
    label: 'Full Name',
    sortable: true,
    sortableKeys: ['firstName', 'lastName'],
    formatter: ({ item }) => (
      <Link to={`/users/${item.id}/user`}>
        {item.firstName} {item.lastName}
      </Link>
    ),
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    key: 'phoneCode',
    label: 'Phone',
    sortable: true,
    sortableKeys: ['phoneCode', 'phoneNumber'],
    formatter: ({ item }) => {
      var pn = new PhoneNumber(`+${item.phoneCode}${item.phoneNumber}`)
      return pn.getNumber('international')
    },
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    formatter: ({ value }) => USER_STATUS.getLabel(value),
  },
  {
    key: 'createdAt',
    label: 'Registration date',
    sortable: true,
    formatter: ({ value }) => format(new Date(value), STANDARD_TIME_DATE),
  },
]

type UsersListFiltersProps = {
  setFilters: (any) => void,
  filters: any,
}

function UsersListFilters(props: UsersListFiltersProps) {
  const dispatch = useDispatch()
  const { setFilters, filters } = props

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen)
  const toggleImportModal = () => setIsImportModalOpen(!isImportModalOpen)
  const toggleExportModal = () => setIsExportModalOpen(!isExportModalOpen)

  const authUser = useSelector(selectAuthUser)

  const exportClients = (e) => {
    e.preventDefault()

    let or = []
    _.map(e.target.statuses, (st) => {
      or.push({ status: st.value })
    })
    const startDate = new Date(e.target.startDate.value).toISOString()
    const endDate = new Date(e.target.endDate.value).toISOString()

    const filter = {
      where: {
        createdAt: {
          between: [startDate, endDate],
        },
        or,
      },
    }

    dispatch(actions.exportClients({ filter }))
  }

  const options = [
    {
      label: 'All Clients',
      value: {
        suspended: false,
        hidden: false,
        status: { inq: ['ACTIVE_CLIENT', 'READY_TO_TRADE', 'VERIFIED', 'IN_VERIFICATION', 'NOT_VERIFIED'] },
      },
    },
    { label: 'Active Clients', value: { suspended: false, hidden: false, status: 'ACTIVE_CLIENT' } },
    { label: 'Account Managers', value: { amAccess: true } },
    { label: 'Partners', value: { isPartner: true } },
    { label: 'Ready to Trade', value: { suspended: false, hidden: false, status: 'READY_TO_TRADE' } },
    { label: 'Ready to Trade', value: { status: 'CLOSED_ACCOUNT' } },
    { label: 'Closed Accounts', value: { status: 'CLOSED' } },
    { label: 'Ready to Fund', value: { suspended: false, hidden: false, status: 'VERIFIED' } },
    { label: 'Application Submitted', value: { suspended: false, hidden: false, status: 'IN_VERIFICATION' } },
    { label: 'Not Approved', value: { suspended: false, hidden: false, status: 'NOT_VERIFIED' } },
    { label: 'Suspended Only', value: { suspended: true } },
    { label: 'Hidden Only', value: { hidden: true } },
  ]

  const onChange = (filter: any) => {
    // setFilters({ ...filters, ...filter.value })
    setFilters(filter.value)
  }

  const statusesOptions = [
    { value: 'ACTIVE_CLIENT', label: 'Active Clients' },
    { value: 'READY_TO_TRADE', label: 'Ready to Trade' },
    { value: 'VERIFIED', label: 'Ready to Fund' },
    { value: 'IN_VERIFICATION', label: 'In Verification' },
    { value: 'NOT_VERIFIED', label: 'Not Approved' },
    { value: 'CLOSED', label: 'Closed Accounts' },
  ]

  const [isOpen, setIsOpen] = useState(false)

  const [closingDate, setClosingDate] = useState(null)
  const [endClosingDate, setEndClosingDate] = useState(null)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <>
      <Row className="mb-3">
        <Col md={12}>
          <InputGroup size="md" className="user_filter">
            <InputGroupAddon addonType="prepend">
              <Button color="primary" onClick={toggle}>
                Filters
              </Button>
            </InputGroupAddon>

            <Input
              style={{ paddingLeft: 20 }}
              autoComplete="hash_276"
              placeholder="Search by IE ID..."
              onChange={(e) => setFilters({ ...filters, ieId: e.target.value })}
            />

            <InputGroupAddon addonType="append">
              {authUser.hasRole([USER_ROLES.EDIT_USERS]) && (
                <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle size="md" color="success" caret>
                    Actions
                  </DropdownToggle>
                  <DropdownMenu right>
                    {authUser.hasRole([USER_ROLES.EDIT_USERS]) && (
                      <>
                        <DropdownItem onClick={toggleCreateModal}>
                          <i className="fas fa-plus" />
                          Create User
                        </DropdownItem>
                      </>
                    )}

                    {authUser.hasRole([USER_ROLES.SUPERADMIN]) && (
                      <>
                        <DropdownItem divider />
                        <DropdownItem onClick={toggleImportModal}>
                          <i className="far fa-arrow-alt-circle-up" />
                          Import Clients
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={toggleExportModal}>
                          <i className="far fa-arrow-alt-circle-down" />
                          Export Clients
                        </DropdownItem>
                      </>
                    )}
                  </DropdownMenu>
                </ButtonDropdown>
              )}
            </InputGroupAddon>
          </InputGroup>
        </Col>

        <CreateUserModal isOpen={isCreateModalOpen} toggleModal={toggleCreateModal} />

        <ImportUsersModal isOpen={isImportModalOpen} toggleModal={toggleImportModal} />

        <Modal isOpen={isExportModalOpen} toggle={toggleExportModal}>
          <Form role="form" onSubmit={exportClients}>
            <ModalHeader tag="h2" toggle={toggleExportModal}>
              Export Clients
            </ModalHeader>

            <ModalBody>
              <p className="text-primary">1. Registration Date</p>
              <InputDateRange />
              <hr />
              <p className="text-primary">2. Status</p>
              <Select
                defaultValue={[statusesOptions[0], statusesOptions[1]]}
                isMulti
                name="statuses"
                id="statuses"
                options={statusesOptions}
                className="form-control"
                classNamePrefix="select"
              />
            </ModalBody>

            <ModalFooter>
              <Button color="simple" onClick={toggleExportModal}>
                <i className="fas fa-times pr-2" />
                Cancel
              </Button>{' '}
              <Button color="success">
                <i className="far fa-file-pdf pr-2" />
                Export to PDF
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Row>
      <Row>
        <Col md={12} className="mb-3">
          <Collapse isOpen={isOpen}>
            <Card color="secondary">
              <CardBody>
                <Row className="mb-2">
                  <Col md={4}>
                    <span className="input-filter-label">Group</span>
                    <Select
                      classNamePrefix="small"
                      defaultValue={options[0]}
                      placeholder="Set Filter..."
                      selectOption="All Clients"
                      options={options}
                      onLoad={onChange}
                      onChange={onChange}
                    />
                  </Col>

                  <Col md={4}>
                    <span className="input-filter-label">Name</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_23"
                      placeholder="Name"
                      onChange={(e) => setFilters({ ...filters, firstName: { ilike: `%${e.target.value}%` } })}
                    />
                  </Col>

                  <Col md={4}>
                    <span className="input-filter-label">Last Name</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_24"
                      placeholder="Last Name"
                      onChange={(e) => setFilters({ ...filters, lastName: { ilike: `%${e.target.value}%` } })}
                    />
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md={4}>
                    <span className="input-filter-label">Email</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_25"
                      placeholder="example@user.com"
                      onChange={(e) => setFilters({ ...filters, email: { like: `%${e.target.value}%` } })}
                    />
                  </Col>

                  <Col md={4}>
                    <span className="input-filter-label">Phone Country Code</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_26"
                      placeholder="44"
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          phoneCode: { like: `%${e.target.value ? parseInt(e.target.value) : ''}%` },
                        })
                      }
                    />
                  </Col>

                  <Col md={4}>
                    <span className="input-filter-label">Phone Number</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_27"
                      placeholder="234567890"
                      onChange={(e) => setFilters({ ...filters, phoneNumber: { like: `%${e.target.value}%` } })}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <span className="input-filter-label">By Account Manager</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_233"
                      placeholder="Account Manager ID"
                      onChange={(e) => setFilters({ ...filters, amOwner: `${e.target.value}` })}
                    />
                  </Col>

                  <Col md={2}>
                    <span className="input-filter-label">CD Start</span>
                    <DatePicker
                      className="input-filter"
                      onChange={(date) => {
                        setClosingDate(date)
                        setFilters({ ...filters, closingDate: date })
                      }}
                      selected={closingDate}
                      showYearDropdown
                      scrollableYearDropdown
                      showMonthDropdown
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      yearDropdownItemNumber={15}
                    />
                  </Col>

                  <Col md={2}>
                    <span className="input-filter-label">CD End</span>
                    <DatePicker
                      className="input-filter"
                      onChange={(date) => {
                        setEndClosingDate(date)
                        setFilters({ ...filters, endClosingDate: date })
                      }}
                      selected={endClosingDate}
                      showYearDropdown
                      scrollableYearDropdown
                      showMonthDropdown
                      isClearable
                      dateFormat="dd.MM.yyyy"
                      yearDropdownItemNumber={15}
                    />
                  </Col>

                  <Col md={4}>
                    <span className="input-filter-label">By Platform Account</span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_2337"
                      placeholder="BL11002030"
                      onChange={(e) => setFilters({ ...filters, platformAccount: `${e.target.value}` })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <span className="input-filter-label" style={{ marginTop: '.5rem' }}>
                      Company Name
                    </span>
                    <Input
                      className="input-filter"
                      autoComplete="hash_2337"
                      placeholder="Acme Inc."
                      onChange={(e) => setFilters({ ...filters, companyName: `${e.target.value}` })}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Row>
    </>
  )
}

export default function UsersListPage() {
  const dispatch = useDispatch()
  const getUsersList = useCallback(({ filters }) => dispatch(actions.getUsersList({ filters })), [dispatch])

  const users = useSelector(selectUsers)
  const totalCount = useSelector(selectUsersTotalCount)
  return (
    <>
      <CardHeader tag="h1">Users</CardHeader>
      <CardBody>
        <Grid
          data={users}
          columns={columns}
          loadDataCallback={getUsersList}
          totalCount={totalCount}
          limit={20}
          filtersComponent={UsersListFilters}
        />
      </CardBody>
    </>
  )
}
