// @flow

import { USER_ROLES } from 'core/Models/Enums';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader, CardBody, Row, Col, ButtonGroup, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import Grid from '../../../Components/Grid';
import { selectAuthUser } from '../../../Selectors';
import CreateManagerModal from './CreateManagerModal';

import { selectManagers, selectManagersTotalCount } from './selectors';

import * as actions from './actions';

const columns = [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    key: 'firstName',
    label: 'Full Name',
    sortable: true,
    sortableKeys: ['firstName', 'lastName'],
    formatter: ({ item }) => (
      <Link to={`/managers/${item.id}/manager`}>
        {item.firstName} {item.lastName}
      </Link>
    ),
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
  },
];

type ManagersListFiltersProps = {
  setFilters: (any) => void,
  filters: any,
};

function ManagersListFilters(props: ManagersListFiltersProps) {
  const { setFilters, filters } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);


  const authUser = useSelector(selectAuthUser);
  const allActive = { suspended: false };
  const suspended = { suspended: true };
  return (
    <Row className="mb-4">
      <Col md={6}>
        <ButtonGroup size="sm" className="mt-2">
          <Button color={isEqual(allActive, filters) ? 'brand' : 'simple'} onClick={() => setFilters(allActive)}>
            Active Managers
          </Button>
          <Button color={isEqual(suspended, filters) ? 'brand' : 'simple'} onClick={() => setFilters(suspended)}>
            Suspended Managers
          </Button>
        </ButtonGroup>
      </Col>
      <Col md={6} className="d-flex justify-content-end">
        {authUser.hasRole([USER_ROLES.SUPERADMIN]) && <Button className="ml-2" color="success" onClick={() => setIsModalOpen(true)}><i className="fas fa-plus pr-2"></i>New Manager</Button>}
        <CreateManagerModal isOpen={isModalOpen} toggleModal={toggleModal} />
      </Col>
    </Row>
  );
}

export default function ManagersListPage() {
  const dispatch = useDispatch();
  const getManagersList = useCallback(({ filters }) => dispatch(actions.getManagersList({ filters })), [dispatch]);


  const managers = useSelector(selectManagers);
  const totalCount = useSelector(selectManagersTotalCount);



  return (
    <>
      <CardHeader tag="h1">Managers</CardHeader>
      <CardBody>
        <Grid
          data={managers}
          columns={columns}
          loadDataCallback={getManagersList}
          totalCount={totalCount}
          limit={50}
          filtersComponent={ManagersListFilters}
        />
      </CardBody>
    </>
  );
}
