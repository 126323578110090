import { createApiAction } from 'core/Utils/reduxUtils';

export const getManager = createApiAction('MANAGER_CARD/GET_MANAGER');
export const updateManager = createApiAction('MANAGER_CARD/UPDATE_MANAGER');

export const getManagerLogs = createApiAction('MANAGER_CARD/GET_MANAGER_LOGS');

export const suspendManager = createApiAction('MANAGER_CARD/SUSPEND_MANAGER');
export const activateManager = createApiAction('MANAGER_CARD/ACTIVATE_MANAGER');

export const exportActivity = createApiAction('MANAGER_CARD/EXPORT_ACTIVITY');
export const setManagerPassword = createApiAction('MANAGER_CARD/SET_MANAGER_PASSWORD');
