import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader, CardBody, Button, Table, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import * as actions from './actions';

import { USER_ROLES } from 'core/Models/Enums';
import { selectAuthUser } from '../../../Selectors';
import { selectSettings } from './selectors';

export default function SettingsPage() {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const settings = useSelector(selectSettings);

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(actions.bitrixGetInfo());
  }, [dispatch]);

  const bitrixSyncAll = (e) => {
    dispatch(actions.bitrixSyncAll());
  };

  return (
    <>
      <CardHeader tag="h1">Settings</CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Sync Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
              }}
            >
              Actions
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-3">
          <TabPane tabId="1">
            {settings.bitrixInfo?.users ? (
              <Table bordered={true}>
                <tbody>
                  <tr>
                    <td>Users in Control Panel</td>
                    <td>{settings.bitrixInfo.users}</td>
                  </tr>
                  <tr>
                    <td>Users in Bitrix CRM (with Client Area ID)</td>
                    <td>{settings.bitrixInfo.crmUsers}</td>
                  </tr>
                  <tr>
                    <td>Total users in Bitrix CRM</td>
                    <td>{settings.bitrixInfo.totalUsers}</td>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <CardBody>Loading information from Bitrix CRM...</CardBody>
            )}
          </TabPane>
          <TabPane tabId="2">
            {authUser.hasRole([USER_ROLES.SUPERADMIN]) ? (
              <Button onClick={bitrixSyncAll} color="primary">
                FORCE SYNC USERS
              </Button>
            ) : (
              <span>No permissions available for this account</span>
            )}
          </TabPane>
        </TabContent>
      </CardBody>
    </>
  );
}
