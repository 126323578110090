// @flow
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import UsersListPage from './UsersListPage';
import UserCardPage from './UserCardPage';

export default function UsersPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={path}
        component={UsersListPage}
      />
      <Route
        path={`${path}/:userId`}
        component={UserCardPage}
      />
    </Switch>
  );
}
