import type { ClientType } from 'core/Models/Client';
import React from 'react';
import { Button, Card, CardBody, CardHeader, CardFooter, Table, ButtonGroup } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import type { UserType } from 'core/Models/User';
import * as actions from '../actions';

import { format } from 'date-fns';
import { STANDARD_TIME_DATE } from 'core/Utils/dateUtils';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
};

export default function BankAccountsList(props: Props) {
  const { userId, user } = props;
  const { url } = useRouteMatch();

  const dispatch = useDispatch();
  const deleteUserBankAccount = ({ accountId }) => dispatch(actions.deleteUserBankAccount({ userId, accountId }));

  return (
    <Card>
      <CardHeader tag="h2" className="blue-background">Bank Accounts</CardHeader>
      {user.bankAccounts.size > 0 &&
        <Table responsive bordered striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Bank</th>
              <th>Account</th>
              <th>Account Beneficiary Name</th>
              <th>Added At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {user.bankAccounts.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>{item.id}</td>
                  <td>{item.bank}</td>
                  <td>{item.account}</td>
                  <td>{item.accountBeneficiary}</td>
                  <td>{format(new Date(item.createdAt), STANDARD_TIME_DATE)}</td>
                  <td>
                    <ButtonGroup>
                      <Button color="primary" size="sm" tag={Link} to={`${url}/${item.id}`}>
                        <i className="far fa-edit"/> Edit
                      </Button>
                      <Button color="warning" size="sm" onClick={() => deleteUserBankAccount({ accountId: item.id })}>
                        <i className="far fa-trash-alt pr-1"/>Delete
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}

          </tbody>
        </Table>
      }
      {user.bankAccounts.size === 0 && <CardBody><span>No Bank Accounts connected</span></CardBody>}
      
      <CardFooter>
        <Button color="success" tag={Link} to={`${url}/create_account`}>
          <i className="fas fa-plus pr-2"></i>Add Bank Account
        </Button>
      </CardFooter>
    </Card>
  );
}
