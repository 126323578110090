import noop from 'lodash/noop';
import { takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as managerApi from 'core/Api/admin/managerApi';

import * as actions from './actions'

export function* bitrixGetInfo({ payload: { onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(managerApi.bitrixGetInfo);
    yield put(actions.bitrixGetInfo.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.bitrixGetInfo.error(e));
    yield call(onError);
  }
}

export function* bitrixSyncAll({ payload: { userId, onSuccess = noop, onError = noop  } = {} }) {
  yield call(toast.success, 'User synchronization started. Please do not repeat the request.');
  yield call(managerApi.bitrixSyncAll);
}

export default [
  takeLatest(actions.bitrixGetInfo, bitrixGetInfo),
  takeLatest(actions.bitrixSyncAll, bitrixSyncAll),
];
