import { call } from 'redux-saga/effects';

import apiSaga, { apiRequest } from '../index';

export function* getManagerRoles() {
  return yield call(apiSaga, apiRequest.get, 'managers/roles');
}

export function* getManagersList({ filters }) {
  return yield call(apiSaga, apiRequest.get, `managers?filter=${encodeURIComponent(JSON.stringify(filters))}`);
}

export function* getManager({ managerId }) {
  return yield call(apiSaga, apiRequest.get, `users/${managerId}`);
}

export function* getManagerLogs({ managerId, filters }) {
  return yield call(apiSaga, apiRequest.get, `logs/${managerId}?filter=${encodeURIComponent(JSON.stringify(filters))}`);
}

export function* createManager({ values }) {
  return yield call(apiSaga, apiRequest.post, 'managers', values);
}

export function* deleteManager() {
  return yield call(apiSaga, apiRequest.delete, 'managers');
}

export function* suspendManager({ managerId }) {
  return yield call(apiSaga, apiRequest.get, `users/${managerId}/suspend`);
}

export function* activateManager({ managerId }) {
  return yield call(apiSaga, apiRequest.get, `users/${managerId}/activate`);
}

export function* getManagerReport({ values }) {
  return yield call(apiSaga, { method: apiRequest.get, config: { responseType: 'blob' } }, `tools/export/activity/${values}`);
}

export function* exportActivity({ filters }) {
  return yield call(apiSaga, { method: apiRequest.get, config: { responseType: 'blob' } }, `tools/export/activity/xlsx?filter=${encodeURIComponent(JSON.stringify(filters))}`);
}

export function* setManagerPassword({ managerId, values }) {
  return yield call(apiSaga, { method: apiRequest.post }, `managers/${managerId}/change-password`, values);
}


export function* bitrixGetInfo() {
  return yield call(apiSaga, apiRequest.get, `tools/crm-info`);
}


export function* bitrixSyncAll() {
  return yield call(apiSaga, apiRequest.get, `tools/crm/sync`);
}
