import type { ClientType } from 'core/Models/Client'
import React from 'react'
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap'

import { Link, useRouteMatch } from 'react-router-dom'
import type { UserType } from 'core/Models/User'

import { format } from 'date-fns'
import { STANDARD_DATE_TIME } from 'core/Utils/dateUtils'

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
}

export default function DueDiligenceList(props: Props) {
  const { client } = props
  const { url } = useRouteMatch()

  return (
    <Card>
      <CardHeader tag="h2" className="blue-background">
        Due Diligence Forms
      </CardHeader>
      {client.ddl.size > 0 && (
        <Table responsive striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Submitted At</th>
              <th>Accepted At</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {client.ddl
              .sort((a, b) => b.id - a.id)
              .map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>DD{item.id}</td>
                    <td>{item.createdAt ? format(new Date(item.createdAt), STANDARD_DATE_TIME) : 'N/A'}</td>
                    <td>{item.acceptedAt ? format(new Date(item.acceptedAt), STANDARD_DATE_TIME) : 'N/A'}</td>
                    <td className="text-right">
                      <Button color="primary" size="sm" tag={Link} to={`${url}/${item.id}`}>
                        View
                      </Button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      )}
      {client.ddl.size === 0 && (
        <CardBody>
          <span>No Due Diligence Forms submitted</span>
        </CardBody>
      )}
    </Card>
  )
}
