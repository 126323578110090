// @flow
import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Card, Col, Container, Row } from 'reactstrap'

import { routesArray } from 'core/Utils/routesUtils'
import RoutesDescription from 'core/Models/RoutesDescription'
import type { UserType } from 'core/Models/User'

import { selectAuthUser } from '../../Selectors'

import TopNavbar from '../../Components/Navbar'
import Sidebar from '../../Components/Sidebar'

import DashboardPage from './DashboardPage'
import UsersPage from './UsersPage'
import ManagersPage from './ManagersPage'
import SettingsPage from './SettingsPage'

export const routes = new RoutesDescription({
  dashboard: {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-chart-bar',
    component: DashboardPage,
    showInSidebar: true,
  },
  users: {
    path: '/users',
    name: 'Users & Clients',
    icon: 'far fa-address-card',
    component: UsersPage,
    showInSidebar: true,
  },
  managers: {
    path: '/managers',
    name: 'Managers',
    icon: 'fas fa-user-cog',
    component: ManagersPage,
    showInSidebar: true,
  },
  settings: {
    path: '/settings',
    name: 'Settings',
    icon: 'fas fa-cog',
    component: SettingsPage,
    showInSidebar: true,
  },
})

const sidebarRoutes = routes.toArray.filter((r) => r.showInSidebar)

export default function InnerPage() {
  const authUser: UserType = useSelector(selectAuthUser)

  const [sidebar, setSidebar] = React.useState(true)
  const toggleSidebar = () => setSidebar(!sidebar)

  return (
    <>
      {sidebar && <Sidebar routes={sidebarRoutes} />}
      <div className="main-content">
        <TopNavbar
          userName={`${authUser.firstName} ${authUser.lastName}`}
          userEmail={authUser.email}
          sidebar={sidebar}
          toggleSidebar={toggleSidebar}
        />
        <Container fluid>
          <Row>
            <Col>
              <Card className="shadow">
                <Switch>
                  <Redirect from="/" exact to={routes.users.path} />
                  {routesArray({ routes })}
                  <Route path="*">
                    <Redirect to={routes.users.path} />
                  </Route>
                </Switch>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <footer className="footer">
                <Row className="align-items-center justify-content-xl-between">
                  <Col xl="12">
                    <div className="copyright text-center text-xl-right text-muted">
                      © 2018–2021 Investors Europe Control Panel, version 2.4.1
                    </div>
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
