// @flow
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink as RouterNavLink, useParams, useRouteMatch } from 'react-router-dom';
import { CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap';

import { routesArray } from 'core/Utils/routesUtils';
import RoutesDescription from 'core/Models/RoutesDescription';

import { selectManager } from './selectors';

import TabHistory from './TabHistory';
import TabManager from './TabManager';

import * as actions from './actions';

export const routes = new RoutesDescription({
  manager: {
    path: '/manager',
    name: 'Manager Information',
    component: TabManager,
  },
  history: {
    path: '/history',
    name: 'History',
    component: TabHistory,
  },
});

export default function ManagerCardPage() {
  const { managerId } = useParams();
  const { url, path } = useRouteMatch();
  const dispatch = useDispatch();
  const getManager = useCallback(() => dispatch(actions.getManager({ managerId })), [dispatch, managerId]);

  const manager = useSelector(selectManager);

  useEffect(() =>{
    getManager();
  }, [getManager, managerId]);

  return (
    <>
      <CardHeader>
        <h1 className="mb-0">
          <Link to="/managers">MANAGERS</Link> / {manager.firstName} {manager.lastName}
        </h1>
      </CardHeader>
      <CardBody className="pb-0">
        <Nav tabs>
          {routes.toArray.map((route) => (
            <NavItem key={route.path}>
              <NavLink
                tag={RouterNavLink}
                to={`${url}${route.path}`}
              >
                {route.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </CardBody>
      {routesArray({ routes, parentPath: path, additionalProps: { manager, managerId } })}
    </>
  );
}
