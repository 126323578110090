// @flow
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink as RouterNavLink, useParams, useRouteMatch } from 'react-router-dom'
import { CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap'

import { routesArray } from 'core/Utils/routesUtils'
import RoutesDescription from 'core/Models/RoutesDescription'

import { selectClient, selectUser } from './selectors'

import TabUser from './TabUser'
import TabClient from './TabClient'
import TabCustomFields from './TabCustomFields'
import TabDocuments from './TabDocuments'
import TabBankAccounts from './TabBankAccounts'
import TabVerification from './TabVerification'
import TabHistory from './TabHistory'
import TabNotes from './TabNotes'
import TabPartner from './TabPartner'
import TabPlatforms from './TabPlatforms'
import TabDueDiligence from './TabDueDiligence'

import * as actions from './actions'

export const routes = new RoutesDescription({
  user: {
    path: '/user',
    name: 'User',
    component: TabUser,
  },
  client: {
    path: '/client',
    name: 'Client',
    component: TabClient,
  },
  documents: {
    path: '/documents',
    name: 'Documents',
    component: TabDocuments,
  },
  bankAccounts: {
    path: '/bank_accounts',
    name: 'Bank Accounts',
    component: TabBankAccounts,
  },
  platforms: {
    path: '/platforms',
    name: 'Platforms',
    component: TabPlatforms,
  },
  verification: {
    path: '/verification',
    name: 'Verification',
    component: TabVerification,
  },
  dueDiligence: {
    path: '/due_diligence',
    name: 'Due Diligence',
    component: TabDueDiligence,
  },
  customFields: {
    path: '/custom_fields',
    name: 'Custom Fields',
    component: TabCustomFields,
  },
  notes: {
    path: '/notes',
    name: 'Notes',
    component: TabNotes,
  },
  partner: {
    path: '/partner',
    name: 'Partner',
    component: TabPartner,
  },
  logs: {
    path: '/logs',
    name: 'Logs',
    component: TabHistory,
  },
})

export default function UserCardPage() {
  const { userId } = useParams()
  const { url, path } = useRouteMatch()
  const dispatch = useDispatch()
  const getUser = useCallback(() => dispatch(actions.getUser({ userId })), [dispatch, userId])

  const user = useSelector(selectUser)
  const client = useSelector(selectClient)

  useEffect(() => {
    getUser()
  }, [getUser, userId])

  return (
    <>
      <CardHeader tag="h1">
        <Link to="/users">USERS</Link> / #{user.id} {user.firstName} {user.lastName}
      </CardHeader>
      <CardBody className="pb-0">
        <Nav tabs>
          {routes.toArray.map((route) => (
            <NavItem key={route.path} className="mb-1">
              <NavLink tag={RouterNavLink} to={`${url}${route.path}`}>
                {route.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </CardBody>
      {user?.id ? routesArray({ routes, parentPath: path, additionalProps: { user, client, userId } }) : null}
    </>
  )
}
