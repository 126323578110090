import noop from 'lodash/noop';
import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as managerApi from 'core/Api/admin/managerApi';

import * as actions from './actions'

export function* getManagersListSaga({ payload: { filters = {}, onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(managerApi.getManagersList, { filters });
    yield put(actions.getManagersList.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getManagersList.error(e));
    yield call(onError);
  }
}

export function* getManagerRolesSaga({ payload: { onSuccess = noop, onError = noop  } = {} }) {
  try {
    const result = yield call(managerApi.getManagerRoles);
    yield put(actions.getManagerRoles.success(result));
    yield call(onSuccess);
  } catch (e) {
    yield put(actions.getManagerRoles.error(e));
    yield call(onError);
  }
}

export function* createManagerSaga({ payload: { values, onSuccess = noop, onError = noop  } = {} }) {
  try {
    yield call(managerApi.createManager, { values });
    yield put(actions.getManagersList());
    yield call(onSuccess);
    yield call(toast.success, 'Manager created');
  } catch (e) {
    yield call(onError);
  }
}


export function* getManagerReportSaga({ payload: { values, onSuccess = noop, onError = noop } = {} }) {
  try {
    const response = yield call(managerApi.getManagerReport, { values });
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'managers_report.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    yield put(actions.getManagerReport.error(e));
  }
}

export default [
  takeLatest(actions.getManagersList, getManagersListSaga),
  takeLatest(actions.getManagerRoles, getManagerRolesSaga),
  takeLatest(actions.createManager, createManagerSaga),
  takeLatest(actions.getManagerReport, getManagerReportSaga),
];
