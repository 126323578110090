// @flow
import * as React from 'react'
import { useEffect, useCallback, useState, useMemo } from 'react'
import { Table } from 'reactstrap'

import Pagination from '../Pagination'

import styles from './styles.module.scss'

const SORT_ORDER = {
  ASC: {
    id: 'ASC',
    icon: <i className="fas fa-arrow-up" />,
  },
  DESC: {
    id: 'DESC',
    icon: <i className="fas fa-arrow-down" />,
  },
  NO_SORT: {
    id: 'NO_SORT',
    icon: <i className="fas fa-arrows-alt-v" />,
  },
}

function defaultFormatter({ value }) {
  return value
}

function prepareFilters(filters) {
  const { orderColumns, limit, skip = 0, fieldsFilters = {} } = filters
  return {
    filters: {
      ...(orderColumns.keys.length
        ? { order: orderColumns.keys.map((column) => `${column} ${orderColumns.dir}`) }
        : {}),
      limit,
      skip,
      where: fieldsFilters,
    },
  }
}

type Props = {
  loadDataCallback: ({ filters: any }) => void,
  data: any,
  columns: Array<{
    label: string,
    key: string,
    sortable?: boolean,
    sortableKeys?: Array<string>,
    formatter?: ({ value: any, item: any }) => React.Node,
  }>,
  totalCount: number,
  limit: number,
  filtersComponent?: any,
}

export default function Grid(props: Props) {
  const { loadDataCallback, data, columns, limit, totalCount, filtersComponent: FiltersComponent } = props

  const [page, setPage] = useState(0)
  const [orderColumns, setOrderColumns] = useState({ keys: [], dir: SORT_ORDER.ASC.id })
  const [fieldsFilters, setFieldsFilters] = useState({})

  const skip = page * limit
  const filters = useMemo(() => prepareFilters({ orderColumns, limit, skip, fieldsFilters }), [
    orderColumns,
    limit,
    skip,
    fieldsFilters,
  ])

  const loadData = useCallback(() => loadDataCallback(filters), [filters, loadDataCallback])

  const onSort = useCallback(
    (keys = []) => {
      if (orderColumns.keys.length && orderColumns.keys.every((k) => keys.includes(k))) {
        setOrderColumns({ keys, dir: orderColumns.dir === SORT_ORDER.ASC.id ? SORT_ORDER.DESC.id : SORT_ORDER.ASC.id })
      } else {
        setOrderColumns({ keys, dir: SORT_ORDER.ASC.id })
      }
    },
    [orderColumns]
  )

  useEffect(() => {
    loadData()
  }, [loadData, filters])

  const pagesCount = Math.ceil(totalCount / limit)

  return (
    <>
      {FiltersComponent ? <FiltersComponent filters={fieldsFilters} setFilters={setFieldsFilters} /> : null}
      <Table responsive hover bordered striped size="sm">
        <thead>
          <tr>
            {columns.map(({ label, key, sortable, sortableKeys }) => {
              if (sortable) {
                const sKeys = sortableKeys ?? [key]
                const dir =
                  orderColumns.keys.length && orderColumns.keys.every((k) => sKeys.includes(k))
                    ? orderColumns.dir
                    : SORT_ORDER.NO_SORT.id
                return (
                  <th key={key} onClick={() => onSort(sKeys)} className={styles.sortableTh}>
                    <span>{label}</span>
                    {SORT_ORDER[dir].icon}
                  </th>
                )
              }
              return (
                <th key={key}>
                  <span>{label}</span>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => {
            const rowKey = item?.id ?? idx
            return (
              <tr key={rowKey}>
                {columns.map(({ key: columnKey, formatter = defaultFormatter }) => (
                  <td key={columnKey}>{formatter({ value: item?.[columnKey], item })}</td>
                ))}
              </tr>
            )
          })}
          {/* {data.size === 0 && <CardBody>No records</CardBody>} */}
        </tbody>
      </Table>

      <div style={{ marginTop: '20px', paddingBottom: '10px', overflowX: 'scroll' }}>
        <p>Pages:</p>
        <Pagination pagesCount={pagesCount} page={page} setPage={setPage} />
      </div>
    </>
  )
}
