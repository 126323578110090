import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {CardBody} from 'reactstrap';

import type { ClientType } from 'core/Models/Client';
import type { UserType } from 'core/Models/User';

import BankAccountsList from './BankAccountsList';
import BankAccountPage from './BankAccountPage';

type Props = {
  userId: number,
  user: UserType,
  client: ClientType,
};

export default function TabBankAccounts(props: Props) {
  const { userId, user, client } = props;
  const { path } = useRouteMatch();

  return (
    <CardBody>
      <Switch>
        <Route
          exact
          path={path}
          render={(routeProps) => <BankAccountsList {...routeProps} userId={userId} client={client} user={user} />}
        />
        <Route
          exact
          path={`${path}/create_account`}
          render={(routeProps) => <BankAccountPage {...routeProps} userId={userId} client={client} user={user} />}
        />
        <Route
          path={`${path}/:accountId`}
          render={(routeProps) => <BankAccountPage {...routeProps} userId={userId} client={client} user={user} />}
        />
      </Switch>
    </CardBody>
  );
}
